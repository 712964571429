import * as React from 'react';

// MUI
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';

// Internal
import {useQuery} from '@apollo/client';
import {GET_TAGS} from '../data/graphql/queries';
import {TagType} from '../data/types';
import en from '../data/en.json';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, tagNames: readonly string[], theme: Theme) {
  return {
    fontWeight:
    tagNames.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface MultipleSelectChipProps {
  tagNames: string[]
  setTagNames: (tagNames: string[]) => void
}

export default function MultipleSelectChip(props: MultipleSelectChipProps) {
  const theme = useTheme();

  const { loading, error, data } = useQuery(GET_TAGS, {
    variables: { featured: true },
  });

  if (loading) return <p>{en.common['loading..']}</p>
  if (error) return <p>{en.common.error}: {error.message}</p>

  const handleChange = (event: SelectChangeEvent<typeof props.tagNames>) => {
    const {
      target: { value },
    } = event;
    props.setTagNames(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <FormControl sx={{ mt: 1, minWidth: 300 }}>
      <InputLabel id="multiple-chip-label">Tags</InputLabel>
      <Select
        labelId="multiple-chip-label"
        id="multiple-chip"
        multiple
        fullWidth
        value={props.tagNames}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {data.tags.map((tag: TagType, _index: Number) => (
          <MenuItem
            key={tag.name}
            value={tag.name}
            style={getStyles(tag.name, props.tagNames, theme)}
          >
            {tag.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}