import * as React from 'react';
// MUI
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import { green, grey } from '@mui/material/colors';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';

// Third Party
import parse from 'html-react-parser';

// Internal
import {useQuery} from '@apollo/client';
import { Link } from "react-router-dom";
import {GET_RELATED_PUBLICATIONS} from '../data/graphql/queries';
import { PublicationType, TagType } from "../data/types";
import PublicationCard from './PublicationCard';
import en from '../data/en.json';

interface PublicationDetailProps {
  publication: PublicationType
}

export default function PublicationDetail(props: PublicationDetailProps) {
  const { loading, error, data } = useQuery(GET_RELATED_PUBLICATIONS, {
    variables: { number_of_publications: 3, publication_uuid: props.publication.uuid },
  });

  if (loading) return <p>{en.common['loading..']}</p>
  if (error) return <p>{en.common.error}: {error.message}</p>

  return (
    <React.Fragment>
      <Grid container justifyContent={"center"} sx={{m: 5, mt: 10}}>
        <Grid item xs={2} />
        <Grid item xs={8} sx={{border: 1, p: 5, borderColor: grey[300]}}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mb: 3,
            }}
          >
            {props.publication.user.profile.photo?.url && (
              <Avatar
                sx={{ m: 1, ml: 0 }}
                alt="userProfile"
                src={props.publication.user.profile.photo?.url}
              />
            )}
            {!props.publication.user.profile.photo?.url && (
              <Avatar sx={{ bgcolor: green[500], m: 1, ml: 0 }}>
                <AccountCircleIcon />
              </Avatar>
            )}
            <Typography variant="body2">
              {props.publication.user.profile.nickname}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "row",
            }}
          >
            <Typography variant="h3">{parse(props.publication.title)}</Typography>
          </Box>

          {props.publication.htmlContent && (
            <Box>{parse(props.publication.htmlContent)}</Box>
          )}

          {props.publication.coverMedia?.url && (
            <Box sx={{display: "flex", flexDirection:"row", alignSelf: "center"}}>
              <img
                alt="coveredMedia"
                src={props.publication.coverMedia.url}
                width={650}
                height={"auto"}
              />
            </Box>
          )}

          <Box display="flex" flexDirection="row" sx={{pt: 3, pb: 3}}>
            {props.publication.tags.map((tag: TagType) => (
              <Chip
                label={tag.name}
                key={tag.name}
                variant="outlined"
                sx={{ mr: 1 }}
              />
            ))}
          </Box>

          <Divider flexItem variant="middle" sx={{color: grey[800]}} />

          <Box display="flex" flexDirection="row" sx={{pt: 3}}>
            <Box sx={{pr: 2}}><FacebookOutlinedIcon /></Box>
            <Box sx={{pr: 2}}><InsertLinkOutlinedIcon /></Box>
          </Box>
        </Box>
        </Grid>
        <Grid item xs={2} />
      </Grid>

      <Box display="flex" flexDirection="row" sx={{pt: 3}}>
        <Typography variant="body2">{en.publications.relatedPublications}</Typography>
      </Box>
      <Grid container flexDirection="row" justifyContent={"center"} sx={{pt: 1}}>
        {data.relatedPublications.map((publication: PublicationType) => (
          <Grid key={publication.uuid} item xs={3} sx={{border: 1, borderColor: grey[300], m: 2, p: 2}}>
            <Link
              to={`/publications/${publication.uuid}`}
              style={{ textDecoration: 'none', color: 'black' }}
            > 
                <PublicationCard publication={publication} hideCoverMedia={true} />
            </Link>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}