import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Box, List, ListItem } from '@mui/material';
import Divider from '@mui/material/Divider';

// Internal
import AppAppBar from './views/AppAppBar';
import withRoot from '../../withRoot';
import en from '../../data/en.json';

const About = () => {
    return (
      <React.Fragment>
        <AppAppBar />

        <Box sx={{ height: '100px', m: 5 }} display="flex" flexDirection="column"> 
          <Typography variant="h2" gutterBottom sx={{m: 3}}>
            About
          </Typography>
          <Typography variant="body1" gutterBottom sx={{m: 3, mb: 1}}>
            InkBowl is at the forefront of innovative, equitable education and transforming the creative landscape. Our cohesive platform utilizes contemporary technology and artificial intelligence to foster holistic growth within the broad literary and art domains.
          </Typography>

          <Typography variant="body1" gutterBottom sx={{m: 3}}>
            Ink Bowl is a selective international literature and art publishing platform that solicits submissions from high school upperclassmen, college and graduate school students across the globe. Our operation headquarters is located in New York, NY, USA.
          </Typography>

          <Typography variant="h3" gutterBottom sx={{m :3}}>
            EssayCraft AI: Our sophisticated, cost-efficient alternative to conventional tutoring
          </Typography>

          <Typography variant="body1" gutterBottom sx={{m: 3}}>
            Here at Ink Bowl, we’re challenging the status quo of traditional education by introducing affordable, contemporary learning methods powered by artificial intelligence. 
            EssayCraft AI equips students with a plethora of interactive learning tools designed to help them engage, improve, and excel in their coursework.

            <List>
              <ListItem>
                <Box>
                  Teacher & Class Specific Feedback: Navigating the Subjectivity of Grading
                  <List>
                    <ListItem>Leverages historical feedback data from graded assignments to offer revision suggestions tailored to meet the preferences of individual teachers.</ListItem>
                    <ListItem>Improves accuracy in predicting teacher criticisms over time with every upload of graded works.</ListItem>
                    <ListItem>Create dedicated spaces for each class to receive relevant feedback and evaluations from different teachers.</ListItem>
                  </List>
                </Box>
              </ListItem>
              <ListItem>Viability: Achieves remarkable precision in detecting trends unperceived by orthodox tutors, exceeding the human ability to identify instructor biases.</ListItem>
              <ListItem>Quick Edit: Conveniently approve or decline proposed general and teacher-targeted revisions to refine your creative or academic work.</ListItem>
              <ListItem>Progress Tracking: Monitors skill progression and identifies areas of improvement, maintaining a detailed log of academic growth.</ListItem>
              <ListItem>Customizable Presets: Provides the option to choose the appropriate tone and writing style.</ListItem>
              <ListItem>Share Your Work: Easily transfer your work for showcasing on your public profile or for consideration for publication.</ListItem>
            </List>

          </Typography>

          <Divider />

          <Typography variant="h3" gutterBottom sx={{m :3}}>
            VerseCanvas:A New Generation of Creative Mediums
          </Typography>

          <Typography variant="body1" gutterBottom sx={{m: 3, mb: 1}}>
            Ink Bowl presents VerseCanvas, a multifaceted creative tool which offers a novel way for students to engage and effortly create new artistic and literary pieces. 
            Transcending the limitations of conventional mediums, our model is designed to enhance, transform, and redefine the possibilities of artistic expression.
          </Typography>

          <Typography variant="body1" gutterBottom sx={{m: 3}}>
            Prompt-Driven Image Generation:
            <List>
              <ListItem>
                Transform Ideas into Visuals: Input a descriptive prompt to seamlessly convert abstract ideas into distinctive, AI-generated imagery. Artistic and Poetic Transmutation: From Visuals to Verses and Beyond
              </ListItem>

              <ListItem>
                Artistic and Poetic Transmutation: Submit visual art or written text to receive unique, AI-generated poetry or artwork inspired by the original piece, blending the realms of visual and literary arts.
              </ListItem>

              <ListItem>
                Customizable Creative Presets: Offers a comprehensive suite of customizable presets, including artistic and linguistic styles, color palettes, and simulated art tools, enabling precise control over the aesthetic and tone of your creations.
              </ListItem>

              <ListItem>
                Share, Publish & Promote Your Work: Facilitates easy transfer of your projects to be displayed on your public profile or into an application for consideration as a digital and in-print publication.
              </ListItem>
            </List>
          </Typography>

          <Divider />

          <Typography variant="h3" gutterBottom sx={{m :3}}>
            Publication
          </Typography>

          <Typography variant="body1" gutterBottom sx={{m: 3}}>
            Ink Bowl opens the door to real-world publishing opportunities. Our mission is to provide students the means to go from a committed or casual hobbyist to an internationally published author or artist. 
            We understand the importance of seeing your name in print and having your work enjoyed by others around the world. Our platform accepts a broad range of visual art forms and non academic literature, working with diverse creators from some of the most prestigious educational institutions spanning six contents. 
            We encourage every student to apply to be selectively featured both in our online and in-print publications.


            We strive to publish work held at a high caliber. Both literary and art submissions will be reviewed and may be subject to rejection for quality and content purposes. You can read more about submission requirements and our standards here.

            Average Submission Acceptance Rate
            <List>
              <ListItem>Visual Art: 35%</ListItem>
              <ListItem>Literature: 25%</ListItem>
            </List>

            All content must be original. Authors and artists will be publically credited but may submit work anonymously under a pseudonym if desired. If you so choose, you will also have the opportunity to personalize a Creator Profile, listing your social media account information, pronouns and adding a profile picture.​
            To apply to be published on the Ink Bowl website, fill out the Publication Application on the Submissions page at the top of the menu bar.
            Ink Bowl offers publication opportunities for a broad range of non-academic projects under the literary and art categories. We do not accept research and literature that cites outside sources.  Ink Bowl encourages you to share as many works as you desire. The subgroups are outlined below.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{m: 3}}>Why be published?</Typography>
          <Typography variant="body2" gutterBottom sx={{m: 3}}>
            Regardless of your motives, achieving publication status is valuable and coveted for a plethora of reasons. Ink Bowl offers the world’s creative and brightest young minds the opportunity to be globally recognized and appreciated for their creations. For students who possess a casual relationship with their work, publication status serves not just to satisfy personal ambitions but as an impressive credential. Those aspiring to build a career through art or literature draw additional benefits. The visibility and validity associated with formal publication aid students in the process of establishing an esteemed reputation. Through international exposure, Ink Bowl provides students with a robust audience– an important step in garnering a strong presence in one’s field. Such credibility is integral when looking to attain publishing contracts and art commissions, and fundamental to maximizing one’s profit long term. Ultimately, Ink Bowl acts as a springboard for students to fulfill their aspirations and gain the initial traction necessary to catalyze a successful artistic career.
          </Typography>

          <Divider />

          <Typography variant="h3" gutterBottom sx={{m :3}}>
            Social Network: Showcase, Connect, and Build Your Audience
          </Typography>

          <Typography variant="body1" gutterBottom sx={{m: 3}}>
            Ink Bowl’s global, multimedia social network is designed not just as a platform for engagement but as a foundational tool for students aspiring to carve out careers in literature and art. 
            It offers an unparalleled opportunity for emerging talents to gain visibility, showcase their creative endeavors, connect with clients, and build a professional portfolio.
            At the heart of our mission is the promotion of comprehensive student development, facilitated through creative expression, participation in a diverse international community, and the exchange of inspiration. 
            By providing an inclusive and global stage, we empower students to share their work, fostering an environment where free speech and self-expression are not just celebrated but are instrumental in launching successful careers in the creative arts.

            By creating your Ink Bowl Creator Account and joining our social network, you’ll be enabled to:

            <List>
              <ListItem>Design Your Public Profile & Showcase Your Work: Customize your online persona; enjoy flexible multimedia posting options on your profile feed.</ListItem>
              <ListItem>Gain visibility and amass an international audience– an imperative step in initiating your artistic career as an author or artist.</ListItem>
              <ListItem>Design Your Digital Portfolio: Compile your works into a digital portfolio, complete with a customized link for easy sharing with third parties, streamlining your professional outreach.</ListItem>
              <ListItem>Community Outreach: Forge connections with fellow students, prospective clients for commissions, and collaborators.</ListItem>
              <ListItem>Get Verified & Highlight Publications: Exhibit your formalized publications on your profile; acquire official status as a published author or artist.</ListItem>
            </List>
          </Typography>

          <Link
            variant="h6"
            underline="none"
            component="button"
            gutterBottom
            onClick={() => window.open("/dashboard", "_blank")}
            sx={{ color: "secondary.dark" }}
          >
            {en.marketing.signUp}
          </Link>
        </Box>
      </React.Fragment>
    );
}

export default withRoot(About);