import { gql } from '@apollo/client';

export const GENERATE_ART_FROM_POETRY = gql`
  mutation generateArtFromPoetry(
      $user_auth0_id: String!, 
      $poetry: String!,
      $art_style: String!,
      $art_tool_style: String!,
    ) {
      generateArtFromPoetry(
        userAuth0Id: $user_auth0_id, 
        poetry: $poetry,
        artStyle: $art_style,
        artToolStyle: $art_tool_style
      ) { url }
    }
`

export const DECIDE_POST = gql`
  mutation decidePost(
    $post_id: String!,
    $decision: String!,
  ) {
    decidePost(
      postId: $post_id,
      decision: $decision,
    ) {
      id
      text
      status
      imageUrl
      user {
        handle
        profile
      }
      createdAt
    }
  }
`

export const UPDATE_POST = gql`
  mutation updatePost(
    $post_id: String!,
    $user_auth0_id: String!,
    $text: String!,
    $image_url: String
  ) {
    updatePost(
      postId: $post_id,
      text: $text,
      userAuth0Id: $user_auth0_id,
      imageUrl: $image_url
    ) {
      id
      text
      imageUrl
      user {
        handle
        profile
      }
      createdAt
    }
  } 
`

export const UPDATE_USER_PRIFILE = gql`
  mutation updateUserProfile(
    $user_auth0_id: String!,
    $handle: String!,
    $pronouns: String,
    $picture_url: String,
    $twitter: String,
    $instagram: String,
    $bio: String,
  ) {
    updateUserProfile(
      userAuth0Id: $user_auth0_id,
      handle: $handle,
      pronouns: $pronouns,
      pictureUrl: $picture_url,
      twitter: $twitter,
      instagram: $instagram,
      bio: $bio
    ) { id email role auth0Id handle verified }
  }
`

export const FOLLOW_USER = gql`
  mutation followUser(
    $user_auth0_id: String!,
    $following_user_handle: String!
  ) {
    followUser(
      userAuth0Id: $user_auth0_id,
      followingUserHandle: $following_user_handle
    ) {
      id
      auth0Id
      handle
      profile
      verified
      posts { id text imageUrl createdAt }
      publications { id status title headline uuid tags { name } }
      followers { handle profile auth0Id }
      following { handle profile auth0Id }
    }
  }
`

export const FLAG_POST = gql`
  mutation flagPost(
    $user_auth0_id: String!,
    $post_id: String!,
  ) {
    flagPost(
      userAuth0Id: $user_auth0_id, 
      postId: $post_id,
    ) {
      id
      text
      imageUrl
      comments { content user { auth0Id profile } createdAt }
      reactions { reactionType user { auth0Id } }
      user {
        profile
        handle
      }
      createdAt
    }
  }
`
export const COMMENT_POST = gql`
  mutation commentPost(
    $user_auth0_id: String!,
    $post_id: String!,
    $comment: String!
  ) {
    commentPost(
      userAuth0Id: $user_auth0_id, 
      postId: $post_id,
      comment: $comment
    ) {
      id
      text
      imageUrl
      comments { content user { auth0Id profile } createdAt }
      reactions { reactionType user { auth0Id } }
      user {
        profile
        handle
      }
      createdAt
    }
  }
`

export const REACT_POST = gql`
  mutation reactPost(
    $user_auth0_id: String!,
    $post_id: String!,
    $reaction_type: String!
  ) {
    reactPost(
      userAuth0Id: $user_auth0_id, 
      postId: $post_id,
      reactionType: $reaction_type
    ) {
      id
      text
      imageUrl
      comments { content user { auth0Id profile } createdAt }
      reactions { reactionType user { auth0Id } }
      user {
        profile
        handle
      }
      createdAt
    }
  }
`

export const CREATE_POST = gql`
  mutation createPost(
    $user_auth0_id: String!,
    $text: String!,
    $image_url: String
  ) {
    createPost(
      userAuth0Id: $user_auth0_id,
      text: $text,
      imageUrl: $image_url
    ) {
      id
      text
      imageUrl
      comments { content }
      reactions { reactionType }
      user {
        profile
        handle
      }
      createdAt
    }
  }
`

export const UPSERT_USER = gql`
  mutation upsertUser(
    $email: String!,
    $auth0_id: String!,
    $role: String!,
    $name: String,
    $picture_url: String
  ) {
    upsertUser(
      email: $email,
      auth0Id: $auth0_id,
      role: $role,
      name: $name,
      pictureUrl: $picture_url
    ) { id email role auth0Id handle verified }
  }
`

export const CREATE_PUBLICATION = gql`
  mutation createPublication(
    $title: String!,
    $kind: String!,
    $headline: String!,
    $tag_names: [String!]!,
    $user_auth0_id: String!,
    $painting_url: String,
    $html_content: String
  ) {
    createPublication(
      title: $title,
      kind: $kind,
      headline: $headline,
      tagNames: $tag_names,
      userAuth0Id: $user_auth0_id,
      paintingUrl: $painting_url,
      htmlContent: $html_content
    ) { id title userId }
  }
`

export const UPDATE_PUBLICATION = gql`
  mutation updatePublication(
    $uuid: String!,
    $title: String!,
    $kind: String!,
    $headline: String!,
    $tag_names: [String!]!,
    $painting_url: String,
    $html_content: String
  ) {
    updatePublication(
      uuid: $uuid,
      title: $title,
      kind: $kind,
      headline: $headline,
      tagNames: $tag_names,
      paintingUrl: $painting_url,
      htmlContent: $html_content
    ) { id title userId }
  }
`

export const DECIDE_PUBLICATION = gql`
  mutation decidePublication(
    $uuid: String!,
    $decision: String!,
    $details: String!,
  ) {
    decidePublication(
      uuid: $uuid,
      decision: $decision,
      details: $details
    ) { id title userId }
  }
`

export const CREATE_SCHOOL_CLASS = gql`
  mutation createSchoolClass(
    $name: String!,
    $professor_name: String!,
    $user_auth0_id: String!
  ) {
    createSchoolClass(
      name: $name,
      professorName: $professor_name,
      userAuth0Id: $user_auth0_id
    ) { uuid name professorName }
  }
`

export const DELETE_SCHOOL_CLASS = gql`
  mutation deleteSchoolClass(
    $uuid: String!,
  ) {
    deleteSchoolClass(
      uuid: $uuid,
    ) { uuid name professorName }
  }
`

export const UPDATE_SCHOOL_CLASS = gql`
  mutation updateSchoolClass(
    $uuid: String!,
    $name: String!,
    $professor_name: String!,
  ) {
    updateSchoolClass(
      uuid: $uuid,
      name: $name,
      professorName: $professor_name
    ) { 
      schoolClass {
        uuid name professorName
      }
      errors
    }
  }
`

export const CREATE_GRADED_ASSIGNMENT = gql`
  mutation createGradedAssignment(
    $name: String!,
    $school_class_uuid: String!
  ) {
    createGradedAssignment(
      name: $name,
      schoolClassUuid: $school_class_uuid
    ) { uuid name }
  }
`

export const DELETE_GRADED_ASSIGNMENT = gql`
  mutation deleteGradedAssignment(
    $uuid: String!,
  ) {
    deleteGradedAssignment(
      uuid: $uuid,
    ) { uuid name }
  }
`

export const CREATE_INPUT_ASSIGNMENT = gql`
  mutation createInputAssignment(
    $name: String!,
    $school_class_uuid: String!
  ) {
    createInputAssignment(
      name: $name,
      schoolClassUuid: $school_class_uuid
    ) { uuid name }
  }
`

export const DELETE_INPUT_ASSIGNMENT = gql`
  mutation deleteInputAssignment(
    $uuid: String!,
  ) {
    deleteInputAssignment(
      uuid: $uuid,
    ) { uuid name }
  }
`

export const ANNOTATE_GRADED_ASSIGNMENT = gql`
  mutation annotateGradedAssignment(
    $uuid: String!,
    $originalText: String!,
    $overallFeedback: String,
    $grade: String,
    $comments: [GradedAssignmentCommentInput!]
  ) {
    annotateGradedAssignment(
      uuid: $uuid,
      originalText: $originalText,
      overallFeedback: $overallFeedback,
      grade: $grade,
      comments: $comments
    ) {
      gradedAssignment {
        uuid
        originalText
        overallFeedback
        grade
        comments {
          uuid
          originalText
          commentText
          offsetStart
          offsetEnd
        }
      }
      errors
    }
  }
`

export const UPDATE_INPUT_ASSIGNMENT = gql`
  mutation updateInputAssignment(
    $uuid: String!,
    $name: String!,
    $originalText: String,
    $tone: String,
    $style: String,
    $genre: String
  ) {
    updateInputAssignment(
      uuid: $uuid,
      name: $name,
      originalText: $originalText,
      tone: $tone,
      style: $style,
      genre: $genre
    ) { 
      inputAssignment {
        uuid name
      }
    }
  }
`

export const GENERATE_AI_SUMMARY_FOR_GRADED_ASSIGNMENT = gql`
  mutation generateAiSummaryForGradedAssignment(
      $graded_assignment_uuid: String!
    ) {
      generateAiSummaryForGradedAssignment(
        gradedAssignmentUuid: $graded_assignment_uuid
      ) { url text }
    }
`

export const GENERATE_AI_SUGGESTIONS_FOR_INPUT_ASSIGNMENT = gql`
  mutation generateAiSuggestionsForInputAssignment(
      $input_assignment_uuid: String!
    ) {
      generateAiSuggestionsForInputAssignment(
        inputAssignmentUuid: $input_assignment_uuid
      ) { text }
    }
`

export const EXTRACT_TEXT_FROM_DOCUMENT_URL = gql`
  mutation extractTextFromDocumentUrl(
      $document_url: String!
    ) {
      extractTextFromDocumentUrl(
        documentUrl: $document_url
      ) { text errors }
    }
`
