import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, List, ListItem } from '@mui/material';

// Internal
import AppAppBar from './views/AppAppBar';
import withRoot from '../../withRoot';

const Guidelines = () => {
    return (
      <React.Fragment>
        <AppAppBar />
        <Box sx={{ height: '100px', m: 5 }} display="flex" flexDirection="column">
          <Typography variant="h2" gutterBottom>
            Community Guidelines
          </Typography>

          <Typography variant="body1" gutterBottom>
            Ink Bowl is a platform that encourages freedom of speech and candid self-expression. However, as a community of diverse creators with a broad audience, we insist that certain publication guidelines must be met to maintain a safe, inclusive, and respectful environment. Any work that jeopardizes the integrity of the platform will be irrefutably rejected. Be mindful of the Community Guidelines below as you draft your application. 
          </Typography>

          <Typography variant="h4" gutterBottom>
            Use of Language
          </Typography>

          <Typography variant="body1" gutterBottom>
            Appropriate use of language is decided by the Ink Bowl Publishing Team on a case-by-case basis. However, content that includes the following will not be accepted: ​​
            Gratuitous usage of profanity and crude remarks
            Slurs targeting any group of people (racial, ethnic, etc.)
          </Typography>

          <Typography variant="h4" gutterBottom>
            Content
          </Typography>

          <Typography variant="body1" gutterBottom>
            Ink Bowl does not condone and will reject any content containing the elements listed below:​
            <List>
              <ListItem>Hate speech and unwarranted malice</ListItem>
              <ListItem>Expressing the support of racism, homophobia, transphobia, ableism, sexism, etc.</ListItem>
              <ListItem>Explicit, pornographic material</ListItem>
              <ListItem>This does not pertain to art or literature illustrating the beauty of the human body</ListItem>
            </List>
          </Typography>

          <Typography variant="h4" gutterBottom>
            Tigger Warnings
          </Typography>

          <Typography variant="body1" gutterBottom>
            Part of our policy to ensure the safety and well-being of Ink Bowl community members is to provide a disclaimer when published content has the potential to be upsetting to others. When submitting an application, we ask that you identify any prospective triggers.

            <List>
              <ListItem>Sexual Violence (rape, assault)</ListItem>
              <ListItem>Violence (broadly, graphic images or descriptions)</ListItem>
              <ListItem>Eating Disorders</ListItem>
              <ListItem>Abuse (emotional, physical)</ListItem>
              <ListItem>Suicide</ListItem>
              <ListItem>Mental Health Disorders</ListItem>
              <ListItem>Substance Abuse</ListItem>
            </List> 
          </Typography>
        </Box>








      </React.Fragment>
    )
}

export default withRoot(Guidelines);