import * as React from 'react';

// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Third Party
import { useMutation } from '@apollo/client';

// Internal
import {
  GENERATE_AI_SUMMARY_FOR_GRADED_ASSIGNMENT
} from '../../data/graphql/mutations';
import MarkdownRenderer from '../../components/MarkdownRenderer';
import CenteredLoadingSpinner from '../../components/CenteredLoadingSpinner';

import {
  GradedAssignmentType,
} from '../../data/types';

interface AiSummaryProps {
  gradedAssignment: GradedAssignmentType;
}

function AiSummary(props: AiSummaryProps) {
  const [generateAiSummaryForGradedAssignment, { 
    error: _GenerateAiSummaryForGradedAssignmentError,
    data: GenerateAiSummaryForGradedAssignmentData,
    loading: GenerateAiSummaryForGradedAssignmentLoading
  }] = useMutation(GENERATE_AI_SUMMARY_FOR_GRADED_ASSIGNMENT);

  const handleGenerateAiSummary = () => {
    generateAiSummaryForGradedAssignment({ variables:
      {
        graded_assignment_uuid: props.gradedAssignment.uuid
      }
    });
  }

  if (GenerateAiSummaryForGradedAssignmentLoading) {
    return <CenteredLoadingSpinner />;
  }

  const renderAiSummary = (markedDownText: string) => (
    <Box sx={{height: "65vh"}}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={2} />
        <Grid item xs={12} md={8}>
          <MarkdownRenderer text={markedDownText} />
        </Grid>
        <Grid item xs={12} md={2} />
      </Grid>
    </Box>
  );

  if (GenerateAiSummaryForGradedAssignmentData) {
    const markedDownText = GenerateAiSummaryForGradedAssignmentData.generateAiSummaryForGradedAssignment.text;
    return renderAiSummary(markedDownText);
  }

  return (
    <Box sx={{height: "65vh"}}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={2} />
        <Grid item xs={12} md={8}>
          <Box display="flex" justifyContent="center" mt={2}>
            <Button variant="contained" onClick={handleGenerateAiSummary}>Generate AI Summary</Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={2} />
      </Grid>
    </Box>
  );
}

export default AiSummary;
