import * as React from 'react';

// MUI
import Box from "@mui/material/Box";
import Avatar from '@mui/material/Avatar';
import { green } from '@mui/material/colors';
import LinearProgress from '@mui/material/LinearProgress';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Typography from "@mui/material/Typography";
import Chip from '@mui/material/Chip';

// Third Party
import parse from 'html-react-parser';

// Internal
import {PublicationType, TagType} from '../data/types';

interface PublicationCardProps {
  publication: PublicationType;
  showContent?: boolean;
  showScrollbar?: boolean;
  hideCoverMedia?: boolean;
}


function PublicationCard(props: PublicationCardProps) {
  const [progress, setProgress] = React.useState<number>(0);
  const boxRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const boxElement = boxRef.current;
    if (boxElement !== null) {
      boxElement.addEventListener('scroll', handleScroll);
      return () => {
        boxElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);


  const renderCoverMedia = (publication: PublicationType) => {
    if (publication.coverMedia?.url) {
      return (
        <Box sx={{display: "flex", flexDirection:"row", alignSelf: "center"}}>
          <img
            alt="coveredMedia"
            src={publication.coverMedia.url} 
            width={"650px"}
            height={"auto"} 
          />
        </Box>
      )
    }
  }

  const handleScroll = () => {
    const element = boxRef.current;
    if (element !== null) {
      const totalScrollHeight = element.scrollHeight - element.clientHeight;
      const scrollPosition = element.scrollTop;
      const scrollPercent = scrollPosition / totalScrollHeight * 100;
      setProgress(scrollPercent);
    }
  }

  return (
    <Box sx={{display: "flex", flexDirection:"column", alignItems: "flex-start" }}>
      <Box sx={{display: "flex", flexDirection:"row", alignItems: "center"}}>
        {props.publication.user.profile.photo?.url && 
          <Avatar sx={{ m: 1, ml: 0 }} alt="userProfile" src={props.publication.user.profile.photo?.url} />
        }
        {!props.publication.user.profile.photo?.url &&
        <Avatar sx={{ bgcolor: green[500], m: 1, ml: 0 }}>
          <AccountCircleIcon />
        </Avatar>}
        <Typography variant="body2">{props.publication.user.profile.nickname}</Typography>
      </Box>
      <Box sx={{display: "flex", flexDirection:"row", alignItems: "flex-start", m: 1, ml: 0, overflowX: 'auto'}}>
        {props.publication.tags.map((tag: TagType, index: number) => (
          <Chip key={index} label={tag.name} variant="outlined" sx={{mr: 1}}/>
        ))}
      </Box>
      <Box ref={boxRef} sx={{maxHeight: 500, overflow: "hidden", overflowY: "scroll"}} onScroll={handleScroll}>
        {props.showScrollbar && <LinearProgress variant="determinate" value={progress} sx={{position: 'absolute', width: 700, alignSelf: 'center'}} />}
        <Typography variant="h4" sx={{p: 1, pl: 0}}>{props.publication.title}</Typography>
        {props.showContent && <Typography variant="body2" sx={{width: 700}}>{parse(props.publication.htmlContent || "")}</Typography>}
      </Box>
      {!props.hideCoverMedia && renderCoverMedia(props.publication)}
    </Box>
  )
}

export default PublicationCard;