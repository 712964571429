// src/components/TextEditor.tsx
import React, { useState, useEffect } from 'react';

interface TextEditorProps {
  initialText: string;
}

interface Suggestion {
  start: number;
  end: number;
  suggestion: string;
  kind: string;
  reason: string;
}

const TextEditor: React.FC<TextEditorProps> = ({ initialText }) => {
  const [text, setText] = useState<string>(initialText);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState<Suggestion | null>(null);
  const [appliedSuggestions, setAppliedSuggestions] = useState<Suggestion[]>([]);

  useEffect(() => {
    // Fetch suggestions from the backend
    fetch(`${process.env.REACT_APP_INKBOWL_API_HOST}/api/suggestions`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + process.env.REACT_APP_INKBOWL_API_TOKEN},
      body: JSON.stringify({ text: initialText }),
    })
      .then((res) => res.json())
      .then((data) => {
        setSuggestions(data.suggestions);
      })
      .catch((err) => {
        console.error('Error fetching suggestions:', err);
      });
  }, [initialText]);

  const handleAcceptSuggestion = (suggestion: Suggestion) => {
    // Apply the suggestion
    const before = text.slice(0, suggestion.start);
    const after = text.slice(suggestion.end);
    const newText = before + suggestion.suggestion + after;

    // Calculate length difference
    const lengthDifference = suggestion.suggestion.length - (suggestion.end - suggestion.start);

    // Update offsets of other suggestions
    const updatedSuggestions = suggestions
      .filter((s) => s !== suggestion)
      .map((s) => {
        if (s.start >= suggestion.end) {
          // Adjust suggestions that come after the applied suggestion
          return {
            ...s,
            start: s.start + lengthDifference,
            end: s.end + lengthDifference,
          };
        } else if (s.end <= suggestion.start) {
          // Suggestions before the applied one remain unchanged
          return s;
        } else {
          // Suggestions that overlap with the applied suggestion are invalidated
          return null;
        }
      })
      .filter((s): s is Suggestion => s !== null);

    setText(newText);
    setAppliedSuggestions([...appliedSuggestions, suggestion]);
    setSuggestions(updatedSuggestions);
    setSelectedSuggestion(null);
  };

  const handleRejectSuggestion = (suggestion: Suggestion) => {
    // Remove the suggestion from the list
    setSuggestions(suggestions.filter((s) => s !== suggestion));
    setSelectedSuggestion(null);
  };

  const renderTextWithHighlights = () => {
    const elements: React.ReactNode[] = [];
    let lastIndex = 0;

    // Sort suggestions by start index
    const sortedSuggestions = [...suggestions].sort((a, b) => a.start - b.start);

    sortedSuggestions.forEach((suggestion, index) => {
      if (appliedSuggestions.includes(suggestion)) {
        return;
      }

      // Handle overlapping suggestions
      if (suggestion.start < lastIndex) {
        return;
      }

      const before = text.slice(lastIndex, suggestion.start);
      const highlighted = text.slice(suggestion.start, suggestion.end);

      if (before) {
        elements.push(
          <span key={`text-${lastIndex}`}>
            {before}
          </span>
        );
      }

      elements.push(
        <span
          key={`suggestion-${index}`}
          className={`highlight-${suggestion.kind}`}
          onClick={() => setSelectedSuggestion(suggestion)}
          title={suggestion.reason}
        >
          {highlighted}
        </span>
      );

      lastIndex = suggestion.end;
    });

    // Add the remaining text
    if (lastIndex < text.length) {
      elements.push(
        <span key={`text-end`}>
          {text.slice(lastIndex)}
        </span>
      );
    }

    return elements;
  };

  return (
    <div>
      <div
        className="text-editor"
        style={{
          whiteSpace: 'pre-wrap', // Preserve spaces and line breaks
          fontSize: '16px',
          lineHeight: '1.6',
          fontFamily: 'Arial, sans-serif',
          color: '#333',
        }}
      >
        {renderTextWithHighlights()}
      </div>
      {selectedSuggestion && (
        <div className="suggestion-popup">
          <p><strong>Suggestion:</strong> {selectedSuggestion.suggestion}</p>
          <p><strong>Reason:</strong> {selectedSuggestion.reason}</p>
          <p><strong>Kind:</strong> {selectedSuggestion.kind}</p>
          <button onClick={() => handleAcceptSuggestion(selectedSuggestion)}>Accept</button>
          <button onClick={() => handleRejectSuggestion(selectedSuggestion)}>Reject</button>
        </div>
      )}
      <style>{`
        .text-editor {
          white-space: pre-wrap;
          font-size: 16px;
          line-height: 1.6;
          font-family: Arial, sans-serif;
          color: #333;
        }
        .highlight-replace {
          background-color: yellow;
          cursor: pointer;
          transition: background-color 0.3s;
        }
        .highlight-replace:hover {
          background-color: orange;
        }
        .highlight-add {
          background-color: blue;
          cursor: pointer;
          transition: background-color 0.3s;
        }
        .highlight-add:hover {
          background-color: green;
        }
        .highlight-delete {
          background-color: red;
          cursor: pointer;
          transition: background-color 0.3s;
        }
        .highlight-delete:hover {
          background-color: pink;
        }
        .suggestion-popup {
          margin-top: 20px;
          padding: 10px;
          border: 1px solid #ccc;
          background-color: #fafafa;
        }
        .suggestion-popup button {
          margin-right: 10px;
        }
      `}</style>
    </div>
  );
};

export default TextEditor;
