import React, { useState, useRef, useEffect } from 'react';

// MUI
import AddCommentIcon from '@mui/icons-material/AddComment';
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

// Third Party
import ContentEditable from 'react-contenteditable';
import { v4 as uuidv4 } from 'uuid';

// Internal
import en from '../../data/en.json';
import CommentModal from './annotate_file/CommentModal';
import OverallFeedbackModal from './annotate_file/OverallFeedbackModal';
import { GradedAssignmentCommentType } from '../../data/types';

interface SelectionOffset {
  start: number;
  end: number;
}

interface AnnotateFileProps {
  originalText: string;
  overallFeedback: string | null;
  grade: string | null;
  comments: GradedAssignmentCommentType[];
  onSetComments: (comments: GradedAssignmentCommentType[]) => void;
  onSetOverallFeedback: (overallFeedback: string|null) => void;
  onSetGrade: (grade: string|null) => void;
}

function AnnotateFile(props: AnnotateFileProps) {
  const [originalContent, setOriginalContent] = useState<string>(props.originalText);
  const [highlightedContent, setHighlightedContent] = useState<string>(props.originalText);
  const [selectedText, setSelectedText] = useState<string>('');
  const [selectionPosition, setSelectionPosition] = useState<{ x: number, y: number } | null>(null);
  const [selectionOffset, setSelectionOffset] = useState<SelectionOffset | null>(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [comments, setComments] = useState<GradedAssignmentCommentType[]>([]);

  const [overallFeedback, setOverallFeedback] = useState<string|null>(props.overallFeedback);
  const [grade, setGrade] = useState<string|null>(props.grade);
  const [isOverallFeedbackModalOpen, setIsOverallFeedbackModalOpen] = useState<boolean>(false);

  const contentEditableRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (props.comments.length > 0) {
      setComments(props.comments);
      updateHighlightedContent(originalContent, props.comments);
    }
  }, [props.comments])

  const handleSelection = () => {
    const selection = window.getSelection();
    if (selection && selection.toString().length > 0 && selection.focusNode && contentEditableRef.current?.contains(selection.focusNode)) {
      if (selection.focusNode.nodeType !== Node.TEXT_NODE) return;

      const range = selection.getRangeAt(0);
      if (selection.focusNode.previousSibling) {
        // NOTE: span is injected, and need to handle this case
        const previousSibling : HTMLElement = selection.focusNode.previousSibling as HTMLElement;
        const comment_uuid = previousSibling.getAttribute('comment-uuid');
        const previous_comment = comments.find(comment => comment.uuid === comment_uuid);

        setSelectionOffset({
          start: range.startOffset + (previous_comment?.offsetEnd || 0),
          end: range.endOffset + (previous_comment?.offsetEnd || 0) }
        );
      } else {
        setSelectionOffset({ start: range.startOffset, end: range.endOffset });
      }

      const rect = range.getBoundingClientRect();
      setSelectionPosition({ x: rect.right + window.scrollX, y: rect.bottom + window.scrollY });
      setSelectedText(selection.toString());
    } 
  };

  const addComment = () => {
    setIsModalOpen(true);
  };

  const addOverallFeedback = () => {
    setIsOverallFeedbackModalOpen(true);
  }

  const handleCommentModalSubmit = (commentText: string) => {
    if (!selectedText) return;
    if (selectionOffset) {
      const newComment: GradedAssignmentCommentType = {
        uuid: uuidv4(),
        commentText: commentText,
        originalText: selectedText,
        offsetStart: selectionOffset.start,
        offsetEnd: selectionOffset.end,
        isVisible: true,
      };

      setComments([...comments, newComment]);
      updateHighlightedContent(originalContent, [...comments, newComment]);
      resetSelection();
    }
  };

  const handleOverallFeedbackModalSubmit = (feedback: string|null, grade: string|null) => {
    setOverallFeedback(feedback);
    props.onSetOverallFeedback(feedback);

    setGrade(grade);
    props.onSetGrade(grade);

    setIsOverallFeedbackModalOpen(false);
  }

	const resetSelection = () => {
    setSelectedText('');
    setSelectionPosition(null);
    setSelectionOffset(null);
  }

  const handleOriginalContentChange = (evt: any) => {
    // NOTE: do not allow content change after finish uploading
  };

  const updateHighlightedContent = (text: string, comments: GradedAssignmentCommentType[]) => {
    let highlightedText = text;
    const offsets: { [key: number]: string } = {};
    let offset = 0;

    comments.forEach((comment) => {
      const startTag = `<span class="highlight" comment-uuid="${comment.uuid}">`;
      const endTag = `</span>`;

      // Keep track of offsets for each insertion
      if (!offsets[comment.offsetStart]) offsets[comment.offsetStart] = '';
      offsets[comment.offsetStart] += startTag;
      if (!offsets[comment.offsetEnd]) offsets[comment.offsetEnd] = '';
      offsets[comment.offsetEnd] += endTag;
    });

    // Insert tags at calculated positions
    Object.keys(offsets).forEach((position: string) => {
      const index = parseInt(position, 10) + offset;
      highlightedText = highlightedText.slice(0, index) + offsets[Number(position)] + highlightedText.slice(index);
      offset += offsets[Number(position)].length;
    });

    setHighlightedContent(highlightedText);
    props.onSetComments(comments);
  };

  const toggleCommentVisibility = (uuid: string) => {
    setComments(prevComments =>
      prevComments.map(comment =>
        comment.uuid === uuid ? { ...comment, isVisible: !comment.isVisible } : comment
      )
    );
  };

  const handleCommentClick = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = evt.target as HTMLElement;
    if (target.classList.contains('highlight')) {
      const uuid = target.getAttribute('comment-uuid') as string;
      toggleCommentVisibility(uuid);
    }
  };

  const handleRemoveComment = (comment: GradedAssignmentCommentType) => {
    const updatedComments = comments.filter(c => c.uuid !== comment.uuid);
    setComments(updatedComments);
    updateHighlightedContent(originalContent, updatedComments);
  }

  const commentCardView = (comment: GradedAssignmentCommentType) => {
    return (
      <Card variant="outlined" sx={{m: 2}}>
        <CardContent>
          <Typography variant="h5" component="div">
            {comment.commentText}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => handleRemoveComment(comment)}>{en.common.remove}</Button>
        </CardActions>
      </Card>
    )
  }

  const addCommentButtonView = (
    (selectionPosition && selectedText) && (
      <IconButton
        sx={{
          position: 'absolute',
          top: selectionPosition.y,
          left: selectionPosition.x,
          cursor: 'pointer',
          zIndex: 1000,
        }}
        onClick={addComment}
      >
        <AddCommentIcon sx={{color: 'black'}} />
      </IconButton>
    )
  )

  useEffect(() => {
    document.addEventListener('selectionchange', handleSelection);
    return () => {
      document.removeEventListener('selectionchange', handleSelection);
    };
  }, [comments]);

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={2} />
        <Grid item xs={12} md={8}>
          <Alert variant="outlined" severity="info" sx={{ mb: 2}}>
            <AlertTitle>How to annotate file</AlertTitle>
            watch a demo on how to annotate a file <a href="#" target="_blank">here</a>.
          </Alert>

          <ContentEditable
            innerRef={contentEditableRef}
            html={highlightedContent}
            disabled={true}
            onChange={handleOriginalContentChange}
            onClick={handleCommentClick}
            className="editable"
            style={{
              width: 'auto',
              height: '60vh',
              border: '1px solid #ccc',
              padding: '5px',
              cursor: 'text',
              overflow: 'auto',
              whiteSpace: 'pre-wrap', // Ensures text wraps to new lines
              wordWrap: 'break-word' // Ensures words wrap correctly within the container 
            }}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', height: "80vh", overflowY: "auto"}}>
            {comments.map((comment) =>
              comment.isVisible ? (
                commentCardView(comment)
              ) : null
            )}
          </Box>
        </Grid>

        <IconButton onClick={addOverallFeedback}>
          <Fab variant="extended" sx={{ mt: 2 }}>
              <AddIcon />
            {en.gradedAssignment.addOverallFeedback}
          </Fab>
        </IconButton>
    
        {addCommentButtonView}

        <CommentModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleCommentModalSubmit}
        />

        <OverallFeedbackModal
          overallFeedback={overallFeedback}
          grade={grade}
          isOpen={isOverallFeedbackModalOpen}
          onClose={() => setIsOverallFeedbackModalOpen(false)}
          onSubmit={(feedback, grade) => handleOverallFeedbackModalSubmit(feedback, grade)}
        />


        <style>
          {`
            .highlight {
              background-color: yellow;
              cursor: pointer;

            }
            .highlight:hover {
              background-color: lightyellow;
            }
          `}
        </style>
      </Grid>
    </Box>
  );
}

export default AnnotateFile;
