import * as React from 'react';

// MUI
import { grey } from '@mui/material/colors';
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

// Third Party
import {useQuery, useMutation} from '@apollo/client';
import { Link } from "react-router-dom";

// Internal
import en from '../../data/en.json';
import {GET_ADMIN_POSTS} from '../../data/graphql/queries';
import {DECIDE_POST} from '../../data/graphql/mutations';
import {PostType, DecisionKindEnum} from '../../data/types';

interface PostsTabType {
  index: number
  label: string
}


export default function AdminCommunity() {
  const { loading, error, data } = useQuery(GET_ADMIN_POSTS, {
    variables: { paging_offset: 0, paging_limit: 100 },
  });
  const [decidePost, { error: DecidePostError, data: DecidePostData }] = useMutation(DECIDE_POST);

  const postsTabTypes: PostsTabType[] = [
    { index: 0, label: en.adminDashboard.communitySection.active},
    { index: 1, label: en.adminDashboard.communitySection.approved},
    { index: 2, label: en.adminDashboard.communitySection.flagged},
    { index: 3, label: en.adminDashboard.communitySection.denied},
  ]

  // helper function
  const updatePosts = (post: PostType) => {
    let index = posts.findIndex((obj) => obj.id === post.id);
    if (index !== -1) { 
      const updatedPosts = [...posts];
      updatedPosts[index] = post;
      setPosts(updatedPosts);
    }
  };

  const handleDecidePost = (decision: DecisionKindEnum, postId: string) => {
    decidePost({ variables: {
      decision: decision,
      post_id: postId
    }})
  }

  const [currentTab, setCurrentTab] = React.useState<PostsTabType>(postsTabTypes[0]);
  const [posts, setPosts] = React.useState<PostType[]>([]);
  const [snackbarMessage, setSnackbarMessage] = React.useState<null|string>(null);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(postsTabTypes.find(tabType => tabType.index === newValue) || currentTab)
  };

  React.useEffect(() => {
    if (data) { setPosts(data.adminPosts as PostType[])}
  }, [data])
  React.useEffect(() => {
    if (DecidePostData) {
      let post : PostType = DecidePostData.decidePost as PostType;
      updatePosts(post);
      setSnackbarMessage(en.adminDashboard.communitySection.decidePostSuccess);
    }
  }, [DecidePostData])

  React.useEffect(() => {
    if (DecidePostError) { setSnackbarMessage(en.adminDashboard.communitySection.decidePostFailure); }
  }, [DecidePostError])

  if (loading) return <p>{en.common['loading..']}</p>
  if (error) return <p>{en.common.error}: {error.message}</p>

  const selectedPosts: PostType[] =
    posts.filter((post: PostType) => post.status === currentTab.label)

  return (
    <React.Fragment>
      <Snackbar 
        open={snackbarMessage !== null} 
        autoHideDuration={3000} 
        message={snackbarMessage}
        onClose={() => setSnackbarMessage(null)}
      />

      <Tabs value={currentTab.index} onChange={handleTabChange}>
        <Tab label={en.adminDashboard.communitySection.active} />
        <Tab label={en.adminDashboard.communitySection.approved} />
        <Tab label={en.adminDashboard.communitySection.flagged} />
        <Tab label={en.adminDashboard.communitySection.denied} />
      </Tabs>
      <Grid container flexDirection="column" alignItems="center">
        {selectedPosts.length > 0 && 
         selectedPosts.map((post: PostType, index: number) => (
          <Box display="flex" flexDirection={"column"} key={index} sx={{mt: 5}} width={650}>
              <Box display="flex" flexDirection={"row"} alignItems={"center"}>
                <Avatar src={post.user.profile?.photo?.url} />
                <Link
                  key={post.user.handle}
                  to={`/users/${post.user.handle}`}
                  target="_blank"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Typography variant="body2" sx={{ fontWeight: "bold", ml: 1, mr: 1 }}>
                    {post.user.profile?.nickname || en.community.unknownUsername}
                  </Typography>
                </Link>
                <Typography variant="body1">{post.createdAt}</Typography>
              </Box>

              <Box sx={{ maxHeight: 250, overflow: "hidden", overflowY: "scroll", mt: 1 }}>
                <Typography variant="body2">{post.text}</Typography>
              </Box>
              <Box>
                {currentTab.label === en.adminDashboard.communitySection.flagged &&
                <Button sx={{mr: 1, mt: 1}} variant="outlined" color="primary" onClick={() => handleDecidePost(DecisionKindEnum.approved, post.id)}>
                  {en.adminDashboard.communitySection.approve}
                </Button>}
                {currentTab.label === en.adminDashboard.communitySection.flagged &&
                <Button sx={{mr: 1, mt: 1}} variant="outlined" color="primary" onClick={() => handleDecidePost(DecisionKindEnum.denied, post.id)}>
                  {en.adminDashboard.communitySection.deny}
                </Button>}
              </Box>
              {post.imageUrl && (
                <img
                  alt="postImage"
                  src={post.imageUrl}
                  width={"100%"}
                  height={"auto"}
                />
              )}
          </Box>
          ))
        }
        {selectedPosts.length === 0 &&
          <Typography variant='h3' color={grey[300]} sx={{mt: 2}}>
            {en.common.noPosts}
          </Typography>
        }
        
      </Grid>
    </React.Fragment>
  )
} 