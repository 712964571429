import * as React from 'react';
// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// Third Party
import { Editor } from '@tinymce/tinymce-react';
import { UploadDropzone } from "@bytescale/upload-widget-react";
import { User as Auth0User } from '@auth0/auth0-react';
import { useMutation } from '@apollo/client';
import { Player } from '@lottiefiles/react-lottie-player';
import { Grammarly, GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import parse from 'html-react-parser';

// Internal
import en from '../../data/en.json';
import {CREATE_PUBLICATION} from '../../data/graphql/mutations';
import { PublicationKindEnum } from '../../data/types';
import MultipleSelectChip from '../../components/MultipleSelectChip';
import Success from '../../assets/animations/success.json';
import Error from '../../assets/animations/error.json';

interface SubmitPublicationStepType {
  step: Number
  label: String
}

interface SubmitPublicationProps {
  user: Auth0User
  publicationId: number | null
}

export default function SubmitPublication(props: SubmitPublicationProps) {
  const steps: SubmitPublicationStepType[] = [
    { step: 0, label: en.common.submitPublicationSection.enterPublicationInfo },
    { step: 1, label: en.common.submitPublicationSection.uploadPublication },
    { step: 2, label: en.common.submitPublicationSection.submitPublication },
  ]

  const [createPublication, { error: CreatePublicationError, data: CreatePublicationData }] 
    = useMutation(CREATE_PUBLICATION);

  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [publicationTitle, setPublicationTitle] = React.useState<string>("");
  const [publicationHeadline, setPublicationHeadline] = React.useState<string>("");
  const [publicationType, setPublicationType] = 
    React.useState<PublicationKindEnum>(PublicationKindEnum.literature);
  const [publicationHtmlContent, setPublicationHtmlContent] =
    React.useState<string|undefined>(undefined);
  const [publicationPaintingUrl, setPublicationPaintingUrl] = 
    React.useState<string|undefined>(undefined);
  const [tagNames, setTagNames] = React.useState<string[]>([]);

  const handleSubmitPublication = () => {
    createPublication({ variables: { 
      title: publicationTitle,
      headline: publicationHeadline,
      user_auth0_id: props.user?.sub,
      tag_names: tagNames,
      kind: publicationType,
      painting_url: publicationPaintingUrl || null, // if painting_url is undefined, convert to null
      html_content: publicationHtmlContent || null, // if html_content is undefined, convert to null
     }
    })
  }

  function SubmitPublicationSteppers() {
    return (
      <Box sx={{ m: 1 }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((step: SubmitPublicationStepType, index: number) => (
            <Step key={index}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    );
  }

  function EnterPublicationInfoStep() {
    const publicationKinds: PublicationKindEnum[] = [
      PublicationKindEnum.literature, 
      PublicationKindEnum.poetry, 
      PublicationKindEnum.visualArt,
    ]

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h4" sx={{ p: 1 }}>
          {en.common.submitPublicationSection.enterPublicationInfo}
        </Typography>

        <FormControl sx={{ width: "50vw" }}>
          <Typography variant="body1" sx={{ pt: 1 }}>
            {en.common.submitPublicationSection.publicationTitleQuestion}
          </Typography>
          <TextField
            required
            id="filled-required"
            label="Required"
            defaultValue={publicationTitle}
            onChange={(e) => {
              setPublicationTitle(e.target.value);
            }}
            variant="outlined"
          />
          <Typography variant="body1" sx={{ pt: 1 }}>
            {
              en.common.submitPublicationSection
                .publicationHeadlineQuestion
            }
          </Typography>

          <TextField
            required
            id="filled-required"
            label="Required"
            defaultValue={publicationHeadline}
            onChange={(e) => {
              setPublicationHeadline(e.target.value);
            }}
            variant="outlined"
          />

          <Typography variant="body1" sx={{ pt: 1 }}>
            {en.common.submitPublicationSection.addTagsToPublication}
          </Typography>
          <MultipleSelectChip tagNames={tagNames} setTagNames={setTagNames}/>

          <Typography variant="body1" sx={{ pt: 1 }}>
            {en.common.submitPublicationSection.publicationTypeQuestion}
          </Typography>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={publicationKinds[0]}
            name="radio-buttons-group"
          >
            {publicationKinds.map(
              (publicationKind: PublicationKindEnum, index: number) => (
                <FormControlLabel
                  key={index}
                  value={publicationKind}
                  control={<Radio />}
                  label={publicationKind}
                  onChange={() => setPublicationType(publicationKind)}
                />
              )
            )}
          </RadioGroup>
        </FormControl>
      </Box>
    );
  }
  
  function UploadPublicationStep() {
    const handleEditorChange = (content: any, editor: any) => {
      setPublicationHtmlContent(content);
    };

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h4" sx={{ p: 1 }}>
          {en.common.submitPublicationSection.uploadPublication}
        </Typography>
        {[PublicationKindEnum.literature, PublicationKindEnum.poetry].includes(
          publicationType
        ) && (
          <Grammarly
            clientId={process.env.REACT_APP_GRAMMARLY_API_KEY}
            config={{
              documentDialect: "american",
              autocomplete: "on",
            }}
          >
            <GrammarlyEditorPlugin
              clientId={process.env.REACT_APP_GRAMMARLY_API_KEY}
              config={{
                documentDialect: "american",
                autocomplete: "on",
              }}
            >
              <Editor
                apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                init={{
                  skin: "snow",
                  height: 500,
                  width: 750,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount code",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic color backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
                onEditorChange={handleEditorChange}
                value={publicationHtmlContent}
              />
            </GrammarlyEditorPlugin>
          </Grammarly>
        )}

        {publicationType === PublicationKindEnum.visualArt && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <UploadDropzone
              options={{apiKey: process.env.REACT_APP_UPLOADER_API_KEY!, maxFileCount: 1, showFinishButton: false}}
              onUpdate={({uploadedFiles}) => {
                const fileUrls: string[] = uploadedFiles.map(x => x.fileUrl);
                setPublicationPaintingUrl(fileUrls[0]);
              }}
            />
          </Box>
        )}
      </Box>
    );
  }

  function SubmitPublicationStep() {
    return (
      <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", mt: 3}}>
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", mt: 3}}>
          <Typography variant='h4' sx={{p: 1}}>
            {en.common.submitPublicationSection.submitPublication}
          </Typography>
        </Box>

        <Box sx={{ml: 10}}>
          <Typography variant='subtitle2' sx={{pt: 1, textTransform: 'capitalize'}}>
            {en.common.submitPublicationSection.publicationTitleQuestion}
          </Typography>
          <Typography variant='body1' sx={{pt: 1}}>{publicationTitle}</Typography>

          <Typography variant='subtitle2' sx={{pt: 1, textTransform: 'capitalize'}}>
            {en.common.submitPublicationSection.publicationHeadlineQuestion}
          </Typography>
          <Typography variant='body1' sx={{pt: 1}}>{publicationHeadline}</Typography>
          <Typography variant='subtitle2' sx={{pt: 1, textTransform: 'capitalize'}}>
            {en.common.submitPublicationSection.publicationTags}
          </Typography>
          {tagNames.map((tagName: string, index: number) => (
            <Chip key={index} label={tagName} variant="outlined" sx={{mr: 1, mt: 1}}/>
          ))}

          <Typography variant='subtitle2' sx={{pt: 1, textTransform: 'capitalize'}}>
            {en.common.submitPublicationSection.publicationContent}
          </Typography>
          {publicationHtmlContent && parse(publicationHtmlContent)}
          {publicationPaintingUrl && <img width={350} src={publicationPaintingUrl} alt="painting" />}
          <FormGroup>
            <FormControlLabel required control={<Checkbox />} label={en.common.submitPublicationSection.acknowledgeTermsAndConditions} />
            <FormControlLabel required control={<Checkbox />} label={en.common.submitPublicationSection.allowEditing} />
          </FormGroup>
        </Box>
      </Box>
    )
  }

  function SubmitPublicationNavigation() {
    return (
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", pt: 2}}>
        { activeStep > 0 && <Button onClick={() => setActiveStep(activeStep - 1)}>{en.common.previous}</Button> }
        { (activeStep < steps.length - 1) && <Button onClick={() => setActiveStep(activeStep + 1)}>{en.common.next}</Button> }
        { (activeStep === steps.length - 1) && <Button onClick={handleSubmitPublication}>{en.common.complete}</Button> }
      </Box>
    )
  }

  if (CreatePublicationData !== undefined) {
    return (
      <Player
        autoplay={true}
        loop={true}
        src={Success}
        style={{ height: '300px', width: '300px' }}
      />
    )
  }

  if (CreatePublicationError !== undefined) {
    return (
      <Player
        autoplay={true}
        loop={true}
        src={Error}
        style={{ height: '300px', width: '300px' }}
      />
    )
  }

  return (
    <React.Fragment>
      {SubmitPublicationSteppers()}

      {activeStep === 0 && EnterPublicationInfoStep()}
      {activeStep === 1 && UploadPublicationStep()}
      {activeStep === 2 && SubmitPublicationStep()}

      {SubmitPublicationNavigation()}
    </React.Fragment>
  )
}
