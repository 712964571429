import * as React from 'react';

// MUI
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';

// Third Party
import { useAuth0 } from "@auth0/auth0-react";

// Internal
import en from '../data/en.json';
import Logo from '../assets/logo-primary.png';

interface SharedAppBarProps {
  returnUrl: string;
}

const rightLink = {
  fontSize: 16,
  color: 'primary.main',
  ml: 3,
};

export default function SharedAppBar(props: SharedAppBarProps) {
  const { isAuthenticated, logout} = useAuth0();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" style={{ background: grey[50] }}>
        <Toolbar sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Box component="img" sx={{ height: 24 }} alt="logo" src={Logo} />
            <Link
              variant="h6"
              underline="none"
              href={props.returnUrl}
              sx={{ pl: 2, fontSize: 24, color: "primary.dark" }}
            >
              {en.common.inkBowl}
            </Link>
            <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
              {isAuthenticated && (
                <Link
                  variant="h6"
                  underline="none"
                  href="/dashboard"
                  sx={{ ...rightLink, color: "secondary.dark" }}
                >
                  {en.common.dashboard}
                </Link>
              )}

              {isAuthenticated && (
                <Link
                  variant="h6"
                  underline="none"
                  href="/community"
                  sx={{ ...rightLink, color: "secondary.dark" }}
                >
                  {en.common.community}
                </Link>
              )}

              {isAuthenticated && (
                <Link
                  variant="h6"
                  component="button"
                  onClick={() =>
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    })
                  }
                  underline="none"
                  sx={{ ...rightLink, color: "secondary.dark" }}
                >
                  {en.common.logout}
                </Link>
              )}

              <Link
                variant="h6"
                underline="none"
                href="/publications"
                sx={{ ...rightLink, color: "secondary.dark" }}
              >
                {en.common.publications}
              </Link>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
