// src/App.tsx
import React, { useState } from 'react';
import TextEditor from './components/TextEditor';

import Box from '@mui/material/Box';


function AppV2() {
  const [inputText, setInputText] = useState<string>('');
  const [submittedText, setSubmittedText] = useState<string>('');

  const handleSubmit = () => {
    setSubmittedText(inputText);
  };

  return (
    <Box sx={{ flexGrow: 1, m: 5, p: 5 }}>
      <h1>EssayCraft V2</h1>
      <div style={{ marginBottom: '20px' }}>
        <textarea
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Enter your text here..."
          rows={10}
          cols={80}
          style={{ fontSize: '16px', padding: '10px' }}
        />
        <br />
        <button onClick={handleSubmit} style={{ marginTop: '10px', padding: '10px 20px', fontSize: '16px' }}>
          Get Suggestions
        </button>
      </div>
      {submittedText && <TextEditor initialText={submittedText} />}
    </Box>
  );
}

export default AppV2;
