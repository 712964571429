import * as React from "react";

// MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Third Party
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useQuery, useMutation } from '@apollo/client';
import { useParams, Link } from 'react-router-dom';

// Internal
import SchoolClassGradedAssignments from './components/GradedAssignments';
import SchoolClassInputAssignments from './components/InputAssignments';
import SharedAppBar from "../../components/SharedAppBar";
import en from '../../data/en.json';
import withRoot from '../../withRoot';
import { SchoolClassType } from '../../data/types';
import { VIEW_SCHOOL_CLASS } from '../../data/graphql/queries';
import { UPDATE_SCHOOL_CLASS } from '../../data/graphql/mutations';

function Main() {
  let { uuid: schoolClassUuid } = useParams();
  const { user: auth0User } = useAuth0();
  const { data: schoolClassData } = useQuery(VIEW_SCHOOL_CLASS, {
    variables: { uuid: schoolClassUuid }
  });

  const [updateSchoolClass, { error: UpdateSchoolClassError, data: UpdateSchoolClassData }] = useMutation(UPDATE_SCHOOL_CLASS);

  const [editClassModalOpen, setEditClassModalOpen] = React.useState<boolean>(false);
  const [schoolClass, setSchoolClass] = React.useState<SchoolClassType | null>(null);
  const [schoolClassName, setSchoolClassName] = React.useState<String>("");
  const [professorName, setProfessorName] = React.useState<String>("");
  const [snackbarMessage, setSnackbarMessage] = React.useState<null|string>(null);

  const handleUpateSchoolClass = () => {
    updateSchoolClass({ variables: { uuid: schoolClassUuid, name: schoolClassName, professor_name: professorName } });
  }

  React.useEffect(() => {
    if (schoolClassData) {
      const schoolClass = schoolClassData.schoolClass as SchoolClassType;
      setSchoolClass(schoolClass);
      setSchoolClassName(schoolClass.name);
      setProfessorName(schoolClass.professorName);
    }
  }, [schoolClassData])

  React.useEffect(() => {
    if (UpdateSchoolClassData) {
      const schoolClass = UpdateSchoolClassData.updateSchoolClass.schoolClass as SchoolClassType;
      setSchoolClass(schoolClass);
      setSchoolClassName(schoolClass.name);
      setProfessorName(schoolClass.professorName);
      setSnackbarMessage(en.schoolClass.editSchoolClassSuccess);
      setEditClassModalOpen(false);
    }
  }, [UpdateSchoolClassData])

  if (!auth0User || !schoolClass) { return <p>{en.common["loading.."]}</p> }

  const editClassModal = (
    <Modal
      open={editClassModalOpen}
      onClose={() => setEditClassModalOpen(false)}
    >
      <Box sx={{
        display: "flex", flexDirection: "column", position: 'absolute',
        top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
        width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4
      }}>
        <Typography variant="h6" noWrap component="div" sx={{color: "black", mb: 2}}>
          {en.schoolClass.editClass}
        </Typography>

        <TextField
          label={en.schoolClass.schoolClassName}
          onChange={(e) => setSchoolClassName(e.target.value)}
          value={schoolClassName}
          variant="outlined"
          sx={{mb: 2}}
        />
        <TextField
          label={en.schoolClass.professorName}
          onChange={(e) => setProfessorName(e.target.value)}
          value={professorName}
          variant="outlined"
          sx={{mb: 2}}
        />

        <Button variant="contained" color="primary" sx={{mt: 2}}
          onClick={() => handleUpateSchoolClass()} disabled={!schoolClassName || !professorName}>
          {en.schoolClass.editClass}
        </Button>
      </Box>
    </Modal>    
  )

  const snackbarMessageView = (
    <Snackbar
      open={snackbarMessage !== null}
      autoHideDuration={3000} 
      message={snackbarMessage}
      onClose={() => setSnackbarMessage(null)}
    />
  )

  return (
    <React.Fragment>
      {editClassModal}
      {snackbarMessageView}
      <SharedAppBar returnUrl="/" />
      <Box sx={{display: "flex", justifyContent: "flex-start", alignItems: "center", mb: 2, mt: 10, ml: 5}}>
        <Typography variant="h4" noWrap component="div" sx={{color: "black", mr: 5}}>
          {schoolClass.name}
        </Typography>

        <Typography variant="h5" noWrap component="div" sx={{color: "black", mr: 2}}>
          {schoolClass.professorName}
        </Typography>

        <IconButton onClick={() => setEditClassModalOpen(true)}>
          <EditIcon />
        </IconButton>
      </Box>

      <Box sx={{display: "flex", justifyContent: "flex-start", alignItems: "center", ml: 5}}>
        <Button variant="contained" color="primary" onClick={() => null } sx={{ mr: 2 }} disabled>
          {en.schoolClass.chatWithAI}
        </Button>

        <Button variant="contained" color="primary" onClick={() => null } sx={{ mr: 2 }} disabled>
          {en.schoolClass.viewProgress}
        </Button>
      </Box>

      <SchoolClassInputAssignments schoolClassUuid={schoolClass.uuid} />
      <SchoolClassGradedAssignments schoolClassUuid={schoolClass.uuid} />
    </React.Fragment>
  )
}

export default withAuthenticationRequired(withRoot(Main),{
  onRedirecting: () => <p>{en.common["loading.."]}</p>
});
