import * as React from 'react';

// MUI
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

// Third Party
import {useQuery} from '@apollo/client';
import { Link as RouterLink } from "react-router-dom";

// Internal
import {GET_ADMIN_PUBLICATIONS} from '../../data/graphql/queries';
import {PublicationType} from '../../data/types';
import en from '../../data/en.json';

// Components
import PublicationCard from '../../components/PublicationCard';
import EditPublication from './components/EditPublication';

interface PublicationsTabType {
  index: number
  label: string
}

export default function AdminPublications() {
  const { loading, error, data } = useQuery(GET_ADMIN_PUBLICATIONS, {
    variables: { paging_offset: 0, paging_limit: 100 },
  });

  const publiationTabTypes: PublicationsTabType[] = [
    { index: 0, label: en.adminDashboard.publicationsSection.pending },
    { index: 1, label: en.adminDashboard.publicationsSection.approved },
  ]

  const [currentTab, setCurrentTab] = React.useState<PublicationsTabType>(publiationTabTypes[0]);
  const [editPublication, setEditPublication] = React.useState<PublicationType|null>(null);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(publiationTabTypes.find(tabType => tabType.index === newValue) || currentTab)
  };

  if (loading) return <p>{en.common['loading..']}</p>
  if (error) return <p>{en.common.error}: {error.message}</p>

  const publications: PublicationType[] = data.adminPublications as PublicationType[]
  const selectedPublications: PublicationType[] =
    publications.filter((publication: PublicationType) => publication.status === currentTab.label)

  const renderPublications = (
    <React.Fragment>
      <Tabs value={currentTab.index} onChange={handleTabChange}>
        <Tab label={en.adminDashboard.publicationsSection.pending} />
        <Tab label={en.adminDashboard.publicationsSection.approved} />
      </Tabs>
      <Grid container flexDirection="column" alignItems="center">
        {selectedPublications.length > 0 && 
        currentTab.label === en.adminDashboard.publicationsSection.pending &&
        selectedPublications.map((publication: PublicationType, index: number) => (
          <Link
            component={"button"}
            onClick={() => setEditPublication(publication)}
            style={{ textDecoration: 'none', color: grey[900] }}
            key={index}
          >
            <Box sx={{width: "45vw", m: 2, p: 2, border: 1, borderColor: grey[300]}}>
              <PublicationCard publication={publication} />
            </Box>
          </Link>
        ))}

        {selectedPublications.length > 0 && 
        currentTab.label === en.adminDashboard.publicationsSection.approved &&
        selectedPublications.map((publication: PublicationType, index: number) => (
          <RouterLink
            to={`/publications/${publication.uuid}`}
            style={{ textDecoration: 'none', color: 'black' }}
            key={index}
          >
            <Box sx={{width: "45vw", m: 2, p: 2, border: 1, borderColor: grey[300]}}>
              <PublicationCard publication={publication} />
            </Box>
          </RouterLink>
        ))}
        
        {selectedPublications.length === 0 &&
          <Typography variant='h3' color={grey[300]} sx={{mt: 2}}>
            {en.common.noPublication}
          </Typography>
        }
      </Grid>
    </React.Fragment>
  )
  if (editPublication) {
    return (
      <EditPublication
        publication={editPublication}
        setEditPublication={setEditPublication}
      />
    )
  }

  return renderPublications;
} 