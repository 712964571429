import * as React from 'react';
import comingSoon from '../../assets/animations/coming-soon.json';
import { Player } from '@lottiefiles/react-lottie-player';

export default function AdminOverview() {
  return (
    <React.Fragment>
      <Player
        autoplay={true}
        loop={false}
        src={comingSoon}
        style={{ height: '300px', width: '300px' }}
      />
    </React.Fragment>
  )
} 