
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Chip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// Third Party
import { useMutation } from '@apollo/client';
import { Grammarly, GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import { Editor } from '@tinymce/tinymce-react';
import { User as Auth0User } from '@auth0/auth0-react';
import { CircularProgress } from '@mui/material';
import parse from 'html-react-parser';
import { Player } from '@lottiefiles/react-lottie-player';
import { useLazyQuery } from '@apollo/client';

// Internal
import { GENERATE_ART_FROM_POETRY, CREATE_PUBLICATION } from '../../data/graphql/mutations';
import { PublicationKindEnum } from '../../data/types';
import en from '../../data/en.json';
import MultipleSelectChip from '../../components/MultipleSelectChip';
import Success from '../../assets/animations/success.json';
import Error from '../../assets/animations/error.json';
import {GET_USER_PROFILE_BY_ID} from '../../data/graphql/queries';

interface GenerateArtFromPoemStepType {
  step: Number
  label: String
}

interface VerseCanvasProps {
  user: Auth0User
}

export default function VerseCanvas(props: VerseCanvasProps) {
  const steps: GenerateArtFromPoemStepType[] = [
    { step: 0, label: en.aiTools.generateArtFromPoetry.submitPoetry},
    { step: 1, label: en.aiTools.generateArtFromPoetry.generateArt},
    { step: 2, label: en.aiTools.generateArtFromPoetry.enterPublicationInfo},
    { step: 3, label: en.aiTools.generateArtFromPoetry.submitPublication},
  ]

  const [createPublication, { error: CreatePublicationError, data: CreatePublicationData }] 
  = useMutation(CREATE_PUBLICATION);

  const [generateArtFromPoetry, { error: GenerateArtFromPoetryError, data: GenerateArtFromPoetryData }] 
  = useMutation(GENERATE_ART_FROM_POETRY);

  const [getUserProfile,
    {
      loading: GetUserProfileLoading,
      error: GetUserProfileError,
      data: GetUserProfileData
    }] = useLazyQuery(GET_USER_PROFILE_BY_ID);

  const [publicationTitle, setPublicationTitle] = React.useState<string>("");
  const [publicationHeadline, setPublicationHeadline] = React.useState<string>("");
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [poetry, setPoetry] = React.useState<string>("");
  const [artStyle, setArtStyle] = React.useState<string>("");
  const [artToolStyle, setArtToolStyle] = React.useState<string>("");
  const [isGeneratingArt, setIsGeneratingArt] = React.useState<boolean>(false);
  const [publicationType, setPublicationType] = 
    React.useState<PublicationKindEnum>(PublicationKindEnum.poetry);
  const [imageUrl, setImageUrl] = React.useState<string>("");
  const [tagNames, setTagNames] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (GenerateArtFromPoetryData) {
      setIsGeneratingArt(false)
      const url = GenerateArtFromPoetryData.generateArtFromPoetry.url
      setImageUrl(url);
    }
  }, [GenerateArtFromPoetryData])

  React.useEffect(() => {
    if (GenerateArtFromPoetryError) {
      setIsGeneratingArt(false)
    }
  }, [GenerateArtFromPoetryError])

  React.useEffect(() => {
    if (props.user) {
      getUserProfile({ variables: { user_auth0_id: props.user.sub } });
    }
  },[props.user])

  if (GetUserProfileLoading) { return <p>{en.common['loading..']}</p>}
  if (GetUserProfileError) { return <p>{en.common.error}: {GetUserProfileError.message}</p> }
  if (GetUserProfileData) {
    if (GetUserProfileData.userProfileById.role !== "admin") {
      return <p>{en.common.error}: {en.adminDashboard.unauthorized}</p>
    }
  }

  function GenerateArtFromPoetrySteppers() {
    return (
      <Box sx={{ m: 1 }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((step: GenerateArtFromPoemStepType, index: number) => (
            <Step key={index}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    );
  }

  const handleSubmitPublication = () => {
    createPublication({ variables: { 
      title: publicationTitle,
      headline: publicationHeadline,
      user_auth0_id: props.user?.sub,
      tag_names: tagNames,
      kind: publicationType,
      painting_url: imageUrl, // if painting_url is undefined, convert to null
      html_content: poetry // if html_content is undefined, convert to null
     }
    })
  }

  function SubmitPoetryStep() {
    const handleEditorChange = (content: any, editor: any) => {
      setPoetry(content);
    };

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h4" sx={{ p: 1 }}>
          {en.aiTools.generateArtFromPoetry.submitPoetry}
        </Typography>

        <Box display={"flex"} flexDirection={"row"} justifyContent={"space-around"} sx={{m: 1}}>
          <Select
            value={artStyle}
            onChange={(e) => setArtStyle(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{mr: 2}}
          >
            <MenuItem value="">
              <em>{en.aiTools.generateArtFromPoetry.artStyle.default}</em>
            </MenuItem>  
            <MenuItem value={en.aiTools.generateArtFromPoetry.artStyle.cubism}>
              {en.aiTools.generateArtFromPoetry.artStyle.cubism}
            </MenuItem>
            <MenuItem value={en.aiTools.generateArtFromPoetry.artStyle.abstract}>
              {en.aiTools.generateArtFromPoetry.artStyle.abstract}
            </MenuItem>
            <MenuItem value={en.aiTools.generateArtFromPoetry.artStyle.expressionism}>
              {en.aiTools.generateArtFromPoetry.artStyle.expressionism}
            </MenuItem>
            <MenuItem value={en.aiTools.generateArtFromPoetry.artStyle.impressionism}>
              {en.aiTools.generateArtFromPoetry.artStyle.impressionism}
            </MenuItem>
            <MenuItem value={en.aiTools.generateArtFromPoetry.artStyle.popArt}>
              {en.aiTools.generateArtFromPoetry.artStyle.popArt}
            </MenuItem>
            <MenuItem value={en.aiTools.generateArtFromPoetry.artStyle.surrealism}>
              {en.aiTools.generateArtFromPoetry.artStyle.surrealism}
            </MenuItem>
          </Select>

          <Select
            value={artToolStyle}
            onChange={(e) => setArtToolStyle(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="">
              <em>{en.aiTools.generateArtFromPoetry.artToolStyle.default}</em>
            </MenuItem>
            <MenuItem value={en.aiTools.generateArtFromPoetry.artToolStyle.charcoal}>
              {en.aiTools.generateArtFromPoetry.artToolStyle.charcoal}
            </MenuItem>
            <MenuItem value={en.aiTools.generateArtFromPoetry.artToolStyle.inkSketch}>
              {en.aiTools.generateArtFromPoetry.artToolStyle.inkSketch}
            </MenuItem>
            <MenuItem value={en.aiTools.generateArtFromPoetry.artToolStyle.oilPainting}>
              {en.aiTools.generateArtFromPoetry.artToolStyle.oilPainting}
            </MenuItem>
            <MenuItem value={en.aiTools.generateArtFromPoetry.artToolStyle.pastel}>
              {en.aiTools.generateArtFromPoetry.artToolStyle.pastel}
            </MenuItem>
            <MenuItem value={en.aiTools.generateArtFromPoetry.artToolStyle.pencilSketch}>
              {en.aiTools.generateArtFromPoetry.artToolStyle.pencilSketch}
            </MenuItem>
            <MenuItem value={en.aiTools.generateArtFromPoetry.artToolStyle.watercolor}>
              {en.aiTools.generateArtFromPoetry.artToolStyle.watercolor}
            </MenuItem>
          </Select>
        </Box>


        <Grammarly
          clientId={process.env.REACT_APP_GRAMMARLY_API_KEY}
          config={{
            documentDialect: "american",
            autocomplete: "on",
          }}
        >
          <GrammarlyEditorPlugin
            clientId={process.env.REACT_APP_GRAMMARLY_API_KEY}
            config={{
              documentDialect: "american",
              autocomplete: "on",
            }}
          >
            <Editor
              apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
              init={{
                skin: "snow",
                height: 500,
                width: 750,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount code",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic color backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
              onEditorChange={handleEditorChange}
              value={poetry}
            />
          </GrammarlyEditorPlugin>
        </Grammarly>
      </Box>)
  }

  function GenerateArtStep() {
    return (
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", pt: 2}}>
        {isGeneratingArt && 
          <React.Fragment>
            <Typography variant="h6" sx={{mr: 1}}>{en.aiTools.generateArtFromPoetry.generatingArt}</Typography>
            <CircularProgress size={30}/>
          </React.Fragment>
        }
        {!isGeneratingArt && imageUrl && <img src={imageUrl} alt="generated art"/>}
      </Box>
    )
  }

  function EnterPublicationInfoStep() {
    const publicationKinds: PublicationKindEnum[] = [
      PublicationKindEnum.literature, 
      PublicationKindEnum.poetry, 
      PublicationKindEnum.visualArt,
    ]

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h4" sx={{ p: 1 }}>
          {en.common.submitPublicationSection.enterPublicationInfo}
        </Typography>

        <FormControl sx={{ width: "50vw" }}>
          <Typography variant="body1" sx={{ pt: 1 }}>
            {en.common.submitPublicationSection.publicationTitleQuestion}
          </Typography>
          <TextField
            required
            id="filled-required"
            label="Required"
            defaultValue={publicationTitle}
            onChange={(e) => {
              setPublicationTitle(e.target.value);
            }}
            variant="outlined"
          />
          <Typography variant="body1" sx={{ pt: 1 }}>
            {
              en.common.submitPublicationSection
                .publicationHeadlineQuestion
            }
          </Typography>

          <TextField
            required
            id="filled-required"
            label="Required"
            defaultValue={publicationHeadline}
            onChange={(e) => {
              setPublicationHeadline(e.target.value);
            }}
            variant="outlined"
          />

          <Typography variant="body1" sx={{ pt: 1 }}>
            {en.common.submitPublicationSection.addTagsToPublication}
          </Typography>
          <MultipleSelectChip tagNames={tagNames} setTagNames={setTagNames}/>

          <Typography variant="body1" sx={{ pt: 1 }}>
            {en.common.submitPublicationSection.publicationTypeQuestion}
          </Typography>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={publicationKinds[0]}
            name="radio-buttons-group"
          >
            {publicationKinds.map(
              (publicationKind: PublicationKindEnum, index: number) => (
                <FormControlLabel
                  key={index}
                  value={publicationKind}
                  control={<Radio />}
                  label={publicationKind}
                  onChange={() => setPublicationType(publicationKind)}
                />
              )
            )}
          </RadioGroup>
        </FormControl>
      </Box>
    );
  }

  function SubmitPublicationStep() {
    return (
      <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", mt: 3}}>
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", mt: 3}}>
          <Typography variant='h4' sx={{p: 1}}>
            {en.common.submitPublicationSection.submitPublication}
          </Typography>
        </Box>

        <Box sx={{ml: 10}}>
          <Typography variant='subtitle2' sx={{pt: 1, textTransform: 'capitalize'}}>
            {en.common.submitPublicationSection.publicationTitleQuestion}
          </Typography>
          <Typography variant='body1' sx={{pt: 1}}>{publicationTitle}</Typography>

          <Typography variant='subtitle2' sx={{pt: 1, textTransform: 'capitalize'}}>
            {en.common.submitPublicationSection.publicationHeadlineQuestion}
          </Typography>
          <Typography variant='body1' sx={{pt: 1}}>{publicationHeadline}</Typography>
          <Typography variant='subtitle2' sx={{pt: 1, textTransform: 'capitalize'}}>
            {en.common.submitPublicationSection.publicationTags}
          </Typography>
          {tagNames.map((tagName: string, index: number) => (
            <Chip key={index} label={tagName} variant="outlined" sx={{mr: 1, mt: 1}}/>
          ))}

          <Typography variant='subtitle2' sx={{pt: 1, textTransform: 'capitalize'}}>
            {en.common.submitPublicationSection.publicationContent}
          </Typography>
          {poetry && parse(poetry)}
          {imageUrl && <img width={350} src={imageUrl} alt="painting" />}
          <FormGroup>
            <FormControlLabel required control={<Checkbox />} label={en.common.submitPublicationSection.acknowledgeTermsAndConditions} />
            <FormControlLabel required control={<Checkbox />} label={en.common.submitPublicationSection.allowEditing} />
          </FormGroup>
        </Box>
      </Box>
    )
  }

  function handleGenerateArtFromPoetry() {
    setIsGeneratingArt(true)
    generateArtFromPoetry({
      variables: {
        poetry: poetry,
        user_auth0_id: props.user.sub,
        art_style: artStyle,
        art_tool_style: artToolStyle
      }
    })
  }

  function handleNextStep() {
    if (steps[activeStep+1].label === en.aiTools.generateArtFromPoetry.generateArt) {
      handleGenerateArtFromPoetry()
    }
    setActiveStep(activeStep + 1)
  }

  function GenerateArtFromPoetryNavigation() {
    return (
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", pt: 2}}>
        { activeStep > 0 && <Button onClick={() => setActiveStep(activeStep - 1)}>{en.common.previous}</Button> }
        { (activeStep < steps.length - 1) && <Button onClick={() => handleNextStep()}>{en.common.next}</Button> }
        { (activeStep === steps.length - 1) && <Button onClick={handleSubmitPublication}>{en.common.complete}</Button> }
      </Box>
    )
  }

  if (CreatePublicationData !== undefined) {
    return (
      <Player
        autoplay={true}
        loop={true}
        src={Success}
        style={{ height: '300px', width: '300px' }}
      />
    )
  }

  if (CreatePublicationError !== undefined || GenerateArtFromPoetryError !== undefined) {
    return (
      <Player
        autoplay={true}
        loop={true}
        src={Error}
        style={{ height: '300px', width: '300px' }}
      />
    )
  }

  return (
    <React.Fragment>
      {GenerateArtFromPoetrySteppers()}

      {activeStep === 0 && SubmitPoetryStep()}
      {activeStep === 1 && GenerateArtStep()}
      {activeStep === 2 && EnterPublicationInfoStep()}
      {activeStep === 3 && SubmitPublicationStep()}

      {GenerateArtFromPoetryNavigation()}
    </React.Fragment>
  );
};