import * as React from 'react';
// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InfoIcon from '@mui/icons-material/Info';
import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// Third Party
import { useQuery, useMutation } from '@apollo/client';
import { Link } from "react-router-dom";

// Internal
import en from '../../../data/en.json';
import { CREATE_GRADED_ASSIGNMENT, DELETE_GRADED_ASSIGNMENT } from '../../../data/graphql/mutations';
import { GET_GRADED_ASSIGNMENTS } from '../../../data/graphql/queries';
import { GradedAssignmentType } from '../../../data/types';

interface SchoolClassGradedAssignmentsProps {
  schoolClassUuid: string
}

export default function SchoolClassGradedAssignments(props: SchoolClassGradedAssignmentsProps) {
  const [ newGradedAssignmentModalOpen, setNewGradedAssignmentModalOpen ] = React.useState(false);
  const handleNewGradedAssignmentModalOpen = () => setNewGradedAssignmentModalOpen(true);
  const handleNewGradedAssignmentModalClose = () => setNewGradedAssignmentModalOpen(false);
  
  const [ gradedAssignmentName, setGradedAssignmentName ] = React.useState<String>("");
  const [ gradedAssignments, setGradedAssignments ] = React.useState<GradedAssignmentType[]>([]); 
  const [ snackbarMessage, setSnackbarMessage ] = React.useState<null|string>(null);

  const { data: GradedAssignmentsData } = useQuery(GET_GRADED_ASSIGNMENTS, {
    variables: { school_class_uuid: props.schoolClassUuid },
  });

  const [createGradedAssignment, { error: CreateGradedAssignmentError, data: CreateGradedAssignmentData }] 
    = useMutation(CREATE_GRADED_ASSIGNMENT);

  const [deleteGradedAssignment, { error: DeleteGradedAssignmentError, data: DeleteGradedAssignmentData }] 
    = useMutation(DELETE_GRADED_ASSIGNMENT);

  React.useEffect(() => {
    if (CreateGradedAssignmentData) {
      setGradedAssignments([CreateGradedAssignmentData.createGradedAssignment, ...gradedAssignments]);
      setNewGradedAssignmentModalOpen(false);
    }
  }, [CreateGradedAssignmentData]);

  React.useEffect(() => {
    if (DeleteGradedAssignmentData) {
      setGradedAssignments(gradedAssignments.filter((gradedAssignment: GradedAssignmentType) => gradedAssignment.uuid !== DeleteGradedAssignmentData.deleteGradedAssignment.uuid));
      setSnackbarMessage(en.schoolClass.gradedAssignmentsView.deleteAssignmentSuccess);
    }
  }, [DeleteGradedAssignmentData]);

  React.useEffect(() => {
    if (GradedAssignmentsData) {
      setGradedAssignments(GradedAssignmentsData.gradedAssignments);
    }
  }, [GradedAssignmentsData]);

  const handleCreateGradedAssignment = () => {
    createGradedAssignment({ variables: {
      name: gradedAssignmentName,
      school_class_uuid: props.schoolClassUuid
    }});
  }

  const handleDeleteGradedAssignment = (gradedAssignmentUuid: string) => {
    deleteGradedAssignment({ variables: { uuid: gradedAssignmentUuid } });
  }

  const newGradedAssignmentModal = (
    <Modal
      open={newGradedAssignmentModalOpen}
      onClose={handleNewGradedAssignmentModalClose}
    >
      <Box sx={{
        display: "flex", flexDirection: "column", position: 'absolute',
        top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
        width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4
      }}>
        <Typography variant="h6" noWrap component="div" sx={{color: "black", mb: 2}}>
          {en.schoolClass.gradedAssignmentsView.createNewGradedAssignment}
        </Typography>

        <TextField
          label={en.schoolClass.gradedAssignmentsView.assignmentName}
          onChange={(e) => setGradedAssignmentName(e.target.value)}
          value={gradedAssignmentName}
          variant="outlined"
          sx={{mb: 2}}
        />
        <Button variant="contained" color="primary" sx={{mt: 2}} onClick={() => handleCreateGradedAssignment()} disabled={!gradedAssignmentName}>
          {en.schoolClass.gradedAssignmentsView.createNewGradedAssignment}
        </Button>

        <Tooltip title={en.schoolClass.gradedAssignmentsView.createNewGradedAssignmentInfo}>
          <InfoIcon sx={{ml: 2, color: "black"}} />
        </Tooltip>
      </Box>
    </Modal>    
  )

  const assignmentsView = (
    <Box sx={{height: "35vh", m: 5}}>
      <Box sx={{display: "flex", justifyContent: "flex-start", alignItems: "center", mb: 2}}>
        <Typography variant="h6" noWrap component="div" sx={{color: "black", mr: 2}}>
          {en.schoolClass.gradedAssignmentsView.title}
        </Typography>
        <Button variant="outlined" color="primary" onClick={() => handleNewGradedAssignmentModalOpen() }>
          {en.schoolClass.gradedAssignmentsView.createNewGradedAssignment}
        </Button>
        <Tooltip title={en.schoolClass.gradedAssignmentsView.createNewGradedAssignmentInfo}>
          <InfoIcon sx={{ml: 2, color: "black"}} />
        </Tooltip>
      </Box>

      <Box sx={{display: "flex", flexDirection: "row"}}>
        {gradedAssignments.map((gradedAssignment: GradedAssignmentType, index: number) => (
          <Card sx={{ minWidth: 275, m: 1 }} key={index}>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {en.schoolClass.gradedAssignmentsView.assignmentName}: {gradedAssignment.name}
              </Typography>

              <Typography variant="h5" component="div">
                {gradedAssignment.name}
              </Typography>
            </CardContent>
          
            <CardActions>
              <Link key={gradedAssignment.uuid}
                to={`/graded_assignments/${gradedAssignment.uuid}`}
                style={{ textDecoration: 'none', color: 'black' }}
              >
                <Button size="small">{en.common.view}</Button>
              </Link>
              <Button size="small" sx={{"color": "red"}}
                onClick={() => handleDeleteGradedAssignment(gradedAssignment.uuid)}
              >
                {en.common.remove}
              </Button>
            </CardActions>
          </Card>
        ))}
      </Box>
    </Box>
  )

  const snackbarMessageView = (
    <Snackbar
      open={snackbarMessage !== null}
      autoHideDuration={3000} 
      message={snackbarMessage}
      onClose={() => setSnackbarMessage(null)}
    />
  )

  return (
    <React.Fragment>
      {snackbarMessageView}
      {newGradedAssignmentModal}
      {assignmentsView}
    </React.Fragment>
  )
}
