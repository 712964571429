import * as React from 'react';
// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// Third Party
import { useQuery, useMutation } from '@apollo/client';
import { Link } from "react-router-dom";

// Internal
import en from '../../../data/en.json';
import { CREATE_INPUT_ASSIGNMENT, DELETE_INPUT_ASSIGNMENT } from '../../../data/graphql/mutations';
import { GET_INPUT_ASSIGNMENTS } from '../../../data/graphql/queries';
import { InputAssignmentType } from '../../../data/types';

interface SchoolClassInputAssignmentsProps {
  schoolClassUuid: string
}

export default function SchoolClassInputAssignments(props: SchoolClassInputAssignmentsProps) {
  const [ newInputAssignmentModalOpen, setNewInputAssignmentModalOpen ] = React.useState(false);
  const handleNewInputAssignmentModalOpen = () => setNewInputAssignmentModalOpen(true);
  const handleNewInputAssignmentModalClose = () => setNewInputAssignmentModalOpen(false);

  const [ inputAssignmentName, setInputAssignmentName ] = React.useState<String>("");
  const [ inputAssignments, setInputAssignments ] = React.useState<InputAssignmentType[]>([]); 
  const [ snackbarMessage, setSnackbarMessage ] = React.useState<null|string>(null);

  const { data: InputAssignmentsData } = useQuery(GET_INPUT_ASSIGNMENTS, {
    variables: { school_class_uuid: props.schoolClassUuid },
  });

  const [createInputAssignment, { error: CreateInputAssignmentError, data: CreateInputAssignmentData }] 
    = useMutation(CREATE_INPUT_ASSIGNMENT);

  const [deleteInputAssignment, { error: DeleteInputAssignmentError, data: DeleteInputAssignmentData }] 
    = useMutation(DELETE_INPUT_ASSIGNMENT);

  React.useEffect(() => {
    if (CreateInputAssignmentData) {
      setInputAssignments([CreateInputAssignmentData.createInputAssignment, ...inputAssignments]);
      setNewInputAssignmentModalOpen(false);
    }
  }, [CreateInputAssignmentData]);

  React.useEffect(() => {
    if (DeleteInputAssignmentData) {
      setInputAssignments(inputAssignments.filter((inputAssignment: InputAssignmentType) => inputAssignment.uuid !== DeleteInputAssignmentData.deleteInputAssignment.uuid));
      setSnackbarMessage(en.schoolClass.inputAssignmentsView.deleteAssignmentSuccess);
    }
  }, [DeleteInputAssignmentData]);

  React.useEffect(() => {
    if (InputAssignmentsData) {
      setInputAssignments(InputAssignmentsData.inputAssignments);
    }
  }, [InputAssignmentsData]);

  const handleCreateInputAssignment = () => {
    createInputAssignment({ variables: {
      name: inputAssignmentName,
      school_class_uuid: props.schoolClassUuid
    }});
  }

  const handleDeleteInputAssignment = (inputAssignmentUuid: string) => {
    deleteInputAssignment({ variables: { uuid: inputAssignmentUuid } });
  }

  const newInputAssignmentModal = (
    <Modal
      open={newInputAssignmentModalOpen}
      onClose={handleNewInputAssignmentModalClose}
    >
      <Box sx={{
        display: "flex", flexDirection: "column", position: 'absolute',
        top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
        width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4
      }}>
        <Typography variant="h6" noWrap component="div" sx={{color: "black", mb: 2}}>
          {en.schoolClass.inputAssignmentsView.createNewInputAssignment}
        </Typography>

        <TextField
          label={en.schoolClass.inputAssignmentsView.assignmentName}
          onChange={(e) => setInputAssignmentName(e.target.value)}
          value={inputAssignmentName}
          variant="outlined"
          sx={{mb: 2}}
        />

        <Button variant="contained" color="primary" sx={{mt: 2}} onClick={() => handleCreateInputAssignment()} disabled={!inputAssignmentName}>
          {en.schoolClass.inputAssignmentsView.createNewInputAssignment}
        </Button>
      </Box>
    </Modal>    
  )

  const assignmentsView = (
    <Box sx={{height: "35vh", m: 5}}>
      <Box sx={{display: "flex", justifyContent: "flex-start", alignItems: "center", mb: 2}}>
        <Typography variant="h6" noWrap component="div" sx={{color: "black", mr: 2}}>
          {en.schoolClass.inputAssignmentsView.title}
        </Typography>
        <Button variant="outlined" color="primary" onClick={() => handleNewInputAssignmentModalOpen() }>
          {en.schoolClass.inputAssignmentsView.createNewInputAssignment}
        </Button>
      </Box>

      <Box sx={{display: "flex", flexDirection: "row"}}>
        {inputAssignments.map((inputAssignment: InputAssignmentType, index: number) => (
          <Card sx={{ minWidth: 275, m: 1 }} key={index}>
            <CardContent>
              <Typography variant="h5" component="div">
                {inputAssignment.name}
              </Typography>
            </CardContent>
          
          <CardActions>
            <Link key={inputAssignment.uuid}
              to={`/input_assignments/${inputAssignment.uuid}`}
              style={{ textDecoration: 'none', color: 'black' }}
            >
              <Button size="small">{en.common.view}</Button>
            </Link>
            <Button size="small" sx={{"color": "red"}} onClick={() => handleDeleteInputAssignment(inputAssignment.uuid)}>{en.common.remove}</Button>
          </CardActions>
          </Card>
        ))}
      </Box>
    </Box>
  )

  const snackbarMessageView = (
    <Snackbar
      open={snackbarMessage !== null}
      autoHideDuration={3000} 
      message={snackbarMessage}
      onClose={() => setSnackbarMessage(null)}
    />
  )

  return (
    <React.Fragment>
      {snackbarMessageView}
      {newInputAssignmentModal}
      {assignmentsView}
    </React.Fragment>
  )
}
