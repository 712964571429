import * as React from 'react';
// Third Party
import { User as Auth0User } from '@auth0/auth0-react';

// Internal
import en from '../../data/en.json';
import EssayCraftSchoolClasses from './components/essay_craft/SchoolClasses';

interface EssayCraftProps {
  user: Auth0User
}

export default function EssayCraft(props: EssayCraftProps) {
  return (
    <React.Fragment>
      <EssayCraftSchoolClasses user={props.user} />
    </React.Fragment>
  )
}
