import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Third Party
import { User as Auth0User } from '@auth0/auth0-react';
import { Link } from "react-router-dom";

// Internal
import { PostType, ReactionType, CommentType } from "../../../data/types";
import en from '../../../data/en.json';

interface PostProps {
  user: Auth0User;
  post: PostType;
  handleReactPost: (postId: string) => void;
  handleFlagPost: (postId: string) => void;
  handleCommentPost: (postId: string, commentText: string) => void;
}

function Post(props: PostProps) {
  const [flagDialogOpen, setFlagDialogOpen] = React.useState<boolean>(false);
  const [commentDialogOpen, setCommentDialogOpen] = React.useState<boolean>(false);
  const [commentText, setCommentText] = React.useState<string>("");

  const handleFlagPost = () => {
    props.handleFlagPost(props.post.id);
    setFlagDialogOpen(false);
  }

  const handleCommentPost = () => {
    props.handleCommentPost(props.post.id, commentText);
    setCommentDialogOpen(false);
    setCommentText("");
  }

  const renderFavoriteIcon = () => {
    const reactedUserAuth0Ids: string[] = props.post.reactions.map((reaction: ReactionType) => reaction.user.auth0Id);
    const reactIcon = (props.user.sub && reactedUserAuth0Ids.includes(props.user.sub)) ?
      <Badge badgeContent={props.post.reactions.length} color="primary">
        <FavoriteIcon />
      </Badge> : <FavoriteBorderIcon />

      return (
        <IconButton onClick={() => props.handleReactPost(props.post.id)}>
          {reactIcon} 
        </IconButton>
      )
  }

  const renderCommentIcon = () => {
    const commentIcon = props.post.comments.length > 0 ? 
      <Badge badgeContent={props.post.comments.length} color="primary">
        <ChatBubbleIcon /> 
      </Badge>:
      <ChatBubbleOutlineIcon />

    return (
      <React.Fragment>
        <IconButton onClick={() => setCommentDialogOpen(true)}>
          {commentIcon}
        </IconButton>

        <Dialog
          fullWidth
          open={commentDialogOpen}
          onClose={() => setCommentDialogOpen(false)}
        >
          <DialogTitle>
            {en.community.comments}
          </DialogTitle>

          <DialogContent>
            <TextField
              multiline
              fullWidth
              variant="standard"
              sx={{maxHeight: 100, overflow: "hidden", overflowY: "scroll"}}
              label={en.community.whatIsOnYourMind}
              InputProps={{ disableUnderline: true }}
              defaultValue={commentText}
              onChange={(e) => {
                setCommentText(e.target.value);
              }}
            />
            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" onClick={() => handleCommentPost()}>{en.community.comment}</Button>
            </Box>

            <Box display="flex" flexDirection="column"
              sx={{maxHeight: 250,overflow: "hidden", overflowY: "scroll"}}
            >
              {props.post.comments.map((comment: CommentType, index: number) => (
                <Box key={index}>
                  <Box display="flex" flexDirection={"row"} sx={{pt: 1}}>
                    <Link
                      key={comment.user.handle}
                      to={`/users/${comment.user.handle}`}
                      target="_blank"
                      style={{ textDecoration: 'none', color: 'black' }}
                    >
                      <Typography variant="body2" sx={{fontWeight: "bold", pr: 1}}>
                        {comment.user.profile?.nickname || en.community.unknownUsername}
                      </Typography>
                    </Link>
                    <Typography variant="body2">{comment.createdAt}</Typography>
                  </Box>
                  <Typography variant="body1">{comment.content}</Typography>
                </Box>
              ))}

            </Box>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => setCommentDialogOpen(false)}>
              {en.common.close}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );

  }

  const renderFlagIcon = () => {
    return (
      <React.Fragment>
        <IconButton onClick={() => setFlagDialogOpen(true)}>
          <ErrorOutlineIcon />
        </IconButton>
        <Dialog
          open={flagDialogOpen}
          onClose={() => setFlagDialogOpen(false)}
        >
          <DialogTitle>
            {en.community.flagPostTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {en.community.flagPostDescription}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setFlagDialogOpen(false)}>{en.common.cancel}</Button>
            <Button onClick={() => handleFlagPost()} autoFocus>
              {en.community.flag}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }

  return (
    <Grid container direction="row" sx={{ p: 2 }}>
      <Grid item alignSelf="self-start" xs={2} sx={{ pl: 2 }}>
        <Avatar src={props.post.user.profile?.photo?.url} />
      </Grid>
      <Grid item xs={10}>
        <Box display="flex" flexDirection={"row"}>
          <Link
            key={props.post.user.handle}
            to={`/users/${props.post.user.handle}`}
            target="_blank"
            style={{ textDecoration: "none", color: "black" }}
          >
            <Typography variant="body2" sx={{ fontWeight: "bold", pr: 1 }}>
              {props.post.user.profile?.nickname || en.community.unknownUsername}
            </Typography>
          </Link>
          <Typography variant="body1">{props.post.createdAt}</Typography>
        </Box>
        <Box sx={{ maxHeight: 250, overflow: "hidden", overflowY: "scroll" }}>
          <Typography variant="body2">{props.post.text}</Typography>
        </Box>
        {props.post.imageUrl && (
          <img
            alt="postImage"
            src={props.post.imageUrl}
            width={"100%"}
            height={"auto"}
          />
        )}
        <Box
          display="flex"
          flexDirection={"row"}
          justifyContent={"space-around"}
          sx={{ pt: 1 }}
        >
          {renderFavoriteIcon()}
          {renderCommentIcon()}
          {renderFlagIcon()}
        </Box>
      </Grid>
    </Grid>
  ); 
}

export default Post;