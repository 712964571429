import * as React from 'react';
// MUI
import Box from '@mui/material/Box';

// Third Party
import { useParams } from 'react-router-dom';
import {useQuery} from '@apollo/client';

// Internal
import SharedAppBar from '../../components/SharedAppBar';
import {VIEW_PUBLICATION} from '../../data/graphql/queries';
import {PublicationType} from '../../data/types';
import PublicationDetail from '../../components/PublicationDetail';
import withRoot from '../../withRoot';
import en from '../../data/en.json';


function ViewPublication() {
  let { uuid } = useParams();
  const { loading, error, data } = useQuery(VIEW_PUBLICATION, {
    variables: { uuid: uuid },
  });
  if (loading) { return <p>{en.common['loading..']}</p>}
  if (error) { return <p>{en.common.error}: {error.message}</p> }

  const publication: PublicationType = data.publication as PublicationType;
  return (
    <React.Fragment>
      <SharedAppBar returnUrl='/dashboard'/>
      <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
        <PublicationDetail publication={publication} />
      </Box>
    </React.Fragment>
  )
}

export default withRoot(ViewPublication);