import * as React from 'react';
// MUI
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddBoxIcon from '@mui/icons-material/AddBox';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

// Third Party
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

// Internal
import MyPublications from './MyPublications';
import SubmitPublication from './SubmitPublication';
import MyProfile from './MyProfile';
import Notifications from './Notifications';
import withRoot from '../../withRoot';
import en from '../../data/en.json';
import Logo from '../../assets/logo-primary.png';
import VerseCanvas from './VerseCanvas';
import EssayCraft from './EssayCraft';

const drawerWidth = 240;

enum UserDashboardSection {
  userProfile = "My Profile",
  notifications = "Notifications",
  verseCanvas = "VerseCanvas",
  essayCraft = "EssayCraft",
  myPublication = "My Publications",
  submitPublication = "Submit Publication",
} 

function Index() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [activeSection, setActiveSection] = React.useState(UserDashboardSection.userProfile);
  const { user, isLoading, logout } = useAuth0();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <Box component="img" sx={{height: 24}} alt="logo" src={Logo} />
        <Link
          variant="h6"
          underline="none"
          href="/"
          sx={{ pl: 2, fontSize: 16, color: "primary.main" }}
        >
          {en.common.inkBowl}
        </Link>
      </Box>

      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => setActiveSection(UserDashboardSection.userProfile)}
          >
            <ListItemIcon>
              <AccountCircleIcon
                color={
                  activeSection === UserDashboardSection.userProfile
                    ? "primary"
                    : "info"
                }
              />
            </ListItemIcon>
            <ListItemText primary={UserDashboardSection.userProfile} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            onClick={() => setActiveSection(UserDashboardSection.notifications)}
          >
            <ListItemIcon>
              <NotificationsIcon
                color={
                  activeSection === UserDashboardSection.notifications
                    ? "primary"
                    : "info"
                }
              />
            </ListItemIcon>
            <ListItemText primary={UserDashboardSection.notifications} />
          </ListItemButton>
        </ListItem>
      </List>

      <Divider />
      <List>
        <ListItem disablePadding>
          <Typography variant="h5" noWrap component="div" sx={{p: 2, pb: 0}}>
            {en.userDashboard.aiTools}
          </Typography>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            onClick={() => setActiveSection(UserDashboardSection.verseCanvas)}
          >
            <ListItemIcon>
              <AutoAwesomeIcon
                color={
                  activeSection === UserDashboardSection.verseCanvas
                    ? "primary"
                    : "info"
                }
              />
            </ListItemIcon>
            <ListItemText primary={UserDashboardSection.verseCanvas} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            onClick={() => setActiveSection(UserDashboardSection.essayCraft)}
          >
            <ListItemIcon>
              <AutoAwesomeIcon
                color={
                  activeSection === UserDashboardSection.essayCraft
                    ? "primary"
                    : "info"
                }
              />
            </ListItemIcon>
            <ListItemText primary={UserDashboardSection.essayCraft} />
          </ListItemButton>
        </ListItem>
      </List>

      <Divider />

      <List>
        <ListItem disablePadding>
          <ListItemButton
            selected={activeSection === UserDashboardSection.myPublication}
            onClick={() => setActiveSection(UserDashboardSection.myPublication)}
          >
            <ListItemIcon>
              <LibraryBooksIcon
                color={
                  activeSection === UserDashboardSection.myPublication
                    ? "primary"
                    : "info"
                }
              />
            </ListItemIcon>
            <ListItemText primary={UserDashboardSection.myPublication} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            onClick={() =>
              setActiveSection(UserDashboardSection.submitPublication)
            }
          >
            <ListItemIcon>
              <AddBoxIcon
                color={
                  activeSection === UserDashboardSection.submitPublication
                    ? "primary"
                    : "info"
                }
              />
            </ListItemIcon>
            <ListItemText primary={UserDashboardSection.submitPublication} />
          </ListItemButton>
        </ListItem>
      </List>

      <Divider />

      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Link
          variant="h6"
          component="button"
          underline="none"
          sx={{ pt: 1, color: "primary.main" }}
          onClick={() =>
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
        >
          {en.common.logout}
        </Link>
      </Box>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          {isLoading && <CircularProgress sx={{color: "white"}}/> }
          {!isLoading && user &&
            <Typography variant="h6" noWrap component="div" sx={{color: "white"}}>
              {en.userDashboard.hello} {user.name}
            </Typography>
          }
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        {activeSection === UserDashboardSection.myPublication && user !== undefined && <MyPublications user={user}/>}
        {activeSection === UserDashboardSection.submitPublication && user !== undefined && <SubmitPublication user={user} publicationId={null} />}
        {activeSection === UserDashboardSection.userProfile && user !== undefined && <MyProfile user={user} />}
        {activeSection === UserDashboardSection.notifications && <Notifications />}
        {activeSection === UserDashboardSection.verseCanvas && user !== undefined && <VerseCanvas user={user}/>}
        {activeSection === UserDashboardSection.essayCraft && user !== undefined && <EssayCraft user={user}/>}
      </Box>
    </Box>
  );
}

export default withAuthenticationRequired(withRoot(Index),{
  onRedirecting: () => <div>{en.common['loading..']}</div>
});
