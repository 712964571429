import * as React from "react";

// MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Snackbar from '@mui/material/Snackbar';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from "@mui/material/Typography";

// Third Party
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

// Internal
import SharedAppBar from "../../components/SharedAppBar";
import CenteredLoadingSpinner from "../../components/CenteredLoadingSpinner";
import en from '../../data/en.json';
import withRoot from '../../withRoot';
import { VIEW_GRADED_ASSIGNMENT } from '../../data/graphql/queries';
import {
  ANNOTATE_GRADED_ASSIGNMENT,
  GENERATE_AI_SUMMARY_FOR_GRADED_ASSIGNMENT
} from '../../data/graphql/mutations';
import {
  GradedAssignmentType,
  GradedAssignmentCommentType,
  GradedAssignmentCommentInput,
  WorkflowStepType
} from '../../data/types';

import UploadFile from './UploadFile';
import AnnotateFile from './AnnotateFile';
import AiSummary from './AiSummary';

function Main() {
  let { uuid: gradedAssignmentUuid } = useParams();

  const { user: auth0User } = useAuth0();
  const [ originalText, setOriginalText ] = React.useState<string>('');
  const [ comments, setCommnents ] = React.useState<GradedAssignmentCommentType[]>([]);
  const [ overallFeedback, setOverallFeedback ] = React.useState<string|null>(null);
  const [ grade, setGrade ] = React.useState<string|null>(null);
  const [ gradedAssignment, setGradedAssignment ] = React.useState<GradedAssignmentType | null>(null);
  const [ snackbarMessage, setSnackbarMessage ] = React.useState<null|string>(null);

  const { data: gradedAssignmentData, error } = useQuery(VIEW_GRADED_ASSIGNMENT, {
    variables: { uuid: gradedAssignmentUuid }
  });

  const [annotateGradedAssignment, { error: _AnnotateGradedAssignmentError, data: AnnotateGradedAssignmentData }] =
    useMutation(ANNOTATE_GRADED_ASSIGNMENT);

  const [generateAiSummaryForGradedAssignment, { error: _GenerateAiSummaryForGradedAssignmentError, data: GenerateAiSummaryForGradedAssignmentData }] =
    useMutation(GENERATE_AI_SUMMARY_FOR_GRADED_ASSIGNMENT);

  const [activeStep, setActiveStep] = React.useState<number>(0);
  const steps: WorkflowStepType[] = [
    { step: 0, label: en.gradedAssignment.uploadFile },
    { step: 1, label: en.gradedAssignment.annotateFile },
    { step: 2, label: en.gradedAssignment.aiSummary },
  ]

  React.useEffect(() => {
    if (gradedAssignmentData) {
      const gradedAssignment = gradedAssignmentData.gradedAssignment as GradedAssignmentType;
      setGradedAssignment(gradedAssignment);
      setOriginalText(gradedAssignment.originalText);
      setOverallFeedback(gradedAssignment.overallFeedback);
      setGrade(gradedAssignment.grade);
      // NOTE: default all comment to be visible
      setCommnents(
        gradedAssignment.comments.map((comment: GradedAssignmentCommentType) => {
          return {
            uuid: comment.uuid,
            originalText: comment.originalText,
            commentText: comment.commentText,
            offsetEnd: comment.offsetEnd,
            offsetStart: comment.offsetStart,
            isVisible: true
          } as GradedAssignmentCommentType
        })
      )
      // NOTE: if originalText is not empty, then we are on annotateFile step
      if (gradedAssignment.originalText) {
        setActiveStep(1);
      }
    }
  }, [gradedAssignmentData])

  if (!auth0User || !gradedAssignment) { return <CenteredLoadingSpinner /> }

  const GradedAssignmentSteppers = () => {
    return (
      <Box sx={{ m: 1 }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((step: WorkflowStepType, index: number) => (
            <Step key={index}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    );
  }

  const handleSaveGradedAssignment = () => {
    annotateGradedAssignment({ variables:
      {
        uuid: gradedAssignment.uuid,
        originalText: originalText,
        overallFeedback: overallFeedback,
        grade: grade,
        comments: comments.map((comment: GradedAssignmentCommentType) => {
          return {
            uuid: comment.uuid,
            originalText: comment.originalText,
            commentText: comment.commentText,
            offsetEnd: comment.offsetEnd,
            offsetStart: comment.offsetStart,
          } as GradedAssignmentCommentInput
        })
      }
    });
    setActiveStep(activeStep + 1);
    setSnackbarMessage(en.gradedAssignment.annotateFileSuccess);
  }


  const handleGoBackToClass = () => {
    window.location.href = '/dashboard';
  }
  
  const GradedAssignmentNavigation = () => {
    return (
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", pt: 2, mb: 2}}>
        { activeStep === 0 && <Button onClick={() => setActiveStep(activeStep + 1)}>{en.common.next}</Button> }
        { activeStep === 1 && <Button onClick={handleSaveGradedAssignment}>{en.common.next}</Button> }
        { activeStep === 2 && <Button onClick={() => setActiveStep(activeStep - 1)}>{en.common.previous}</Button> }
        { activeStep === 2 && <Button onClick={handleGoBackToClass}>{en.gradedAssignment.goBackToClass}</Button> }
      </Box>
    )
  }

  return (
    <React.Fragment>
      <Snackbar
        open={snackbarMessage !== null}
        autoHideDuration={3000}
        message={snackbarMessage}
        onClose={() => setSnackbarMessage(null)}
      />
      <SharedAppBar returnUrl="/" />
      <Box sx={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", mb: 2, mt: 15, ml: 5}}>
        <Typography variant="h4" noWrap component="div" sx={{color: "black", mr: 5}}>
          {gradedAssignment.name}
        </Typography>
      </Box>

      {GradedAssignmentSteppers()}
      {activeStep === 0 && <UploadFile onSetOriginalText={setOriginalText} />}
      {activeStep === 1 &&
          <AnnotateFile originalText={originalText} comments={comments}
            overallFeedback={overallFeedback} grade={grade}
            onSetOverallFeedback={setOverallFeedback} onSetGrade={setGrade}
            onSetComments={setCommnents}/>
      }

      {activeStep === 2 && gradedAssignment && <AiSummary gradedAssignment={gradedAssignment} />}
      {GradedAssignmentNavigation()}

    </React.Fragment>
  )
}

export default withAuthenticationRequired(withRoot(Main),{
  onRedirecting: () => <CenteredLoadingSpinner />
});
