import React from 'react';

// MUI
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// Internal
import en from '../../../data/en.json';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (text: string) => void;
}

const CommentModal: React.FC<ModalProps> = ({ isOpen, onClose, onSubmit }) => {
  const [text, setText] = React.useState('');

  const handleSubmit = () => {
    onSubmit(text);
    setText('');
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={{
          transform: 'translate(-50%, -50%)',
          position: 'fixed',
          width: 400,
          bgcolor: 'background.paper',
          top: '50%',
          left: '50%',
          p: 5
        }}
      >
        <Typography variant="h6" component="h2">
          {en.gradedAssignment.addComment}
        </Typography>
        <TextField multiline rows={4} fullWidth variant="outlined" defaultValue={text} onChange={(e) => {setText(e.target.value)}} />
        <Button onClick={handleSubmit} variant="contained" color="primary" sx={{mt: 2}}>
          {en.common.submit}
        </Button>
      </Box>
    </Modal>
  )
};

export default CommentModal;
