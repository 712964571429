export enum PublicationKindEnum {
  literature = "literature", 
  poetry = "poetry", 
  visualArt = "visualArt",
}

export enum DecisionKindEnum {
  approved = "approved",
  denied = "denied",
}

export enum ReactionTypeEnum {
  like = "like",
  love = "love",
  surprise = "surprise",
  sad = "sad"
}

export interface UserType {
  id: string;
  email: string;
  auth0Id: string;
  profile: {
    nickname: string;
    photo: {
      url: string;
    },
    pronouns: string;
    twitter: string;
    instagram: string;
    bio: string;
  }
  handle: string;
  role: string;
  verified: boolean;
  publications: PublicationType[];
  posts: PostType[];
  followers: UserType[];
  following: UserType[];
}

export interface TagType {
  name: string;
}

export interface PublicationType {
  id: string;
  title: string;
  uuid: string;
  status: string;
  htmlContent: string;
  headline: string;
  kind: string;
  coverMedia: {
    url: string;
  } 
  media: {
    url: string;
  }
  user: UserType;
  tags: TagType[]
}

export interface CommentType {
  user: UserType;
  content: string;
  createdAt: string;
}

export interface WorkflowStepType {
  step: Number
  label: String
}

export interface ReactionType {
  user: UserType;
  reactionType: string;
}

export interface PostType {
  id: string;
  user: UserType;
  text: string;
  status: string;
  comments: CommentType[];
  reactions: ReactionType[];
  imageUrl?: string;
  createdAt: string;
}

export interface SchoolClassType {
  uuid: string;
  name: string;
  professorName: string;
  user: UserType;
  gradedAssignments: GradedAssignmentType[];
}

export interface GradedAssignmentType {
  uuid: string;
  name: string;
  user: UserType;
  originalText: string;
  overallFeedback: string;
  grade: string;
  comments: GradedAssignmentCommentType[];
}

export interface InputAssignmentType {
  uuid: string;
  name: string;
  user: UserType;
  originalText: string;
  tone: string;
  style: string;
  genre: string;
}

export interface GradedAssignmentCommentType {
  uuid: string;
  originalText: string;
  commentText: string;
  offsetStart: number;
  offsetEnd: number;
  isVisible: boolean;
}

export interface GradedAssignmentCommentInput{
  uuid: string;
  originalText: string;
  commentText: string;
  offsetStart: number;
  offsetEnd: number;
}

export type Suggestion = {
  id: number;
  start: number; 
  end: number;
  type: 'new' | 'remove' | 'replace';
  suggestion: string;
  reason: string;
};
