import * as React from "react";

// MUI
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Snackbar from '@mui/material/Snackbar';
import Typography from "@mui/material/Typography";
import { grey } from "@mui/material/colors";

// Third Party
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useQuery, useMutation } from '@apollo/client';

// Internal
import Post from './components/Post';
import SharedCompose from '../../components/SharedCompose';
import SharedAppBar from "../../components/SharedAppBar";
import { GET_PUBLIC_POSTS } from '../../data/graphql/queries';
import { CREATE_POST, REACT_POST, COMMENT_POST, FLAG_POST } from '../../data/graphql/mutations';
import { GET_USER_PROFILE_BY_ID } from '../../data/graphql/queries';

import { PostType, ReactionTypeEnum, UserType } from '../../data/types'
import withRoot from '../../withRoot';

import en from '../../data/en.json';

function Main() {
  const { user: auth0User } = useAuth0();
  const { data: publicPostsData } = useQuery(GET_PUBLIC_POSTS, {
    variables: { user_auth0_id: auth0User?.sub, paging_offset: 0, paging_limit: 1000 },
  });
  const { data: UserProfileData } = useQuery(GET_USER_PROFILE_BY_ID, {
    variables: { user_auth0_id: auth0User?.sub },
  });

  const [createPost, { data: CreatePostData, error: CreatePostError }] 
    = useMutation(CREATE_POST);

  const [reactPost, { data: ReactPostData, error: ReactPostError  }] 
    = useMutation(REACT_POST);

  const [commentPost, { data: CommentPostData, error: CommentPostError  }] 
    = useMutation(COMMENT_POST);
  
  const [flagPost, { error: FlagPostError, data: FlagPostData }] 
    = useMutation(FLAG_POST);

  const [publicPosts, setPublicPosts] = React.useState<PostType[]>([]);
  const [user, setUser] = React.useState<UserType|null>(null);
  const [snackbarMessage, setSnackbarMessage] = React.useState<null|string>(null);

  // helper function
  const updatePublicPosts = (post: PostType) => {
    let index = publicPosts.findIndex((obj) => obj.id === post.id);
    if (index !== -1) {
      const updatedPublicPosts = [...publicPosts];
      updatedPublicPosts[index] = post;
      setPublicPosts(updatedPublicPosts);
    }
  };

  React.useEffect(() => {
    if (UserProfileData) { setUser(UserProfileData.userProfileById as UserType); }
  }, [UserProfileData])

  React.useEffect(() => {
    if (CreatePostData) {
      const newPost : PostType = CreatePostData.createPost as PostType;
      const updatedPublicPosts = [newPost, ...publicPosts];
      setPublicPosts(updatedPublicPosts);
      setSnackbarMessage(en.community.postSuccess);
    }
  }, [CreatePostData])

  React.useEffect(() => {
    if (publicPostsData) { setPublicPosts(publicPostsData.publicPosts as PostType[]); }
  }, [publicPostsData])

  React.useEffect(() => {
    if (ReactPostData) {
      let post : PostType = ReactPostData.reactPost as PostType;
      updatePublicPosts(post);
      setSnackbarMessage(en.community.reactPostSuccess);
    }
  }, [ReactPostData])

  React.useEffect(() => {
    if (CommentPostData) {
      let post : PostType = CommentPostData.commentPost as PostType;
      updatePublicPosts(post);
      setSnackbarMessage(en.community.commentPostSuccess);
    }
  }, [CommentPostData])

  React.useEffect(() => {
    if (FlagPostData) {
      let post : PostType = FlagPostData.flagPost as PostType;
      let updatedPublicPosts = publicPosts.filter((obj) => obj.id !== post.id)
      setPublicPosts(updatedPublicPosts);
      setSnackbarMessage(en.community.flagPostSuccess);
    }
  }, [FlagPostData])

  React.useEffect(() => {
    if (CreatePostError) { setSnackbarMessage(en.community.postFailure); }
    if (ReactPostError) { setSnackbarMessage(en.community.reactPostFailure); }
    if (CommentPostError) { setSnackbarMessage(en.community.commentPostFailure); }
    if (FlagPostError) { setSnackbarMessage(en.community.flagPostFailure); }
  }, [CreatePostError, ReactPostError, CommentPostError, FlagPostError])


  const handleCreatePost = (text: string, imageUrl: string|null) => {
    if (auth0User) {
      createPost({ variables: { 
        user_auth0_id: auth0User.sub,
        text: text,
        image_url: imageUrl
      }
      })
    }
  }

  const handleReactPost = (postId: string) => {
    if (auth0User) {
      reactPost({ variables: { 
          user_auth0_id: auth0User.sub,
          reaction_type: ReactionTypeEnum.love,
          post_id: postId
        }
      })
    }
  }

  const handleCommentPost = (postId: string, commentText: string) => {
    if (auth0User) {
      commentPost({ variables: { 
          user_auth0_id: auth0User.sub,
          post_id: postId,
          comment: commentText
        }
      })
    }
  }

  const handleFlagPost = (postId: string) => {
    if (auth0User) {
      flagPost({ variables: { 
          user_auth0_id: auth0User.sub,
          post_id: postId
        }
      })
    }
  }

  if (!auth0User) { return <p>{en.common["loading.."]}</p> }

  return (
    <React.Fragment>
      <Snackbar 
        open={snackbarMessage !== null} 
        autoHideDuration={3000} 
        message={snackbarMessage}
        onClose={() => setSnackbarMessage(null)}
      />
      <SharedAppBar returnUrl="/" />
      <Grid container justifyContent={"center"} direction="row" sx={{mt: 5}}>
        <Box alignItems="flex-start" sx={{borderLeft: 1, borderRight: 1, borderColor: grey[200], width: 750}}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            padding: 2,
            paddingTop: 10,
            borderBottom: 1,
            borderColor: grey[200],
          }}
        >
          {en.common.home}
        </Typography>
          {auth0User && <SharedCompose user={user} handleCreatePost={handleCreatePost}/>}

          {publicPosts.map((postData: PostType, key: number) => (
            <Post key={key} user={auth0User} post={postData} 
              handleReactPost={handleReactPost}
              handleCommentPost={handleCommentPost}
              handleFlagPost={handleFlagPost}
            />
          ))}
        </Box>
      </Grid>
    </React.Fragment>
  );
}

export default withAuthenticationRequired(withRoot(Main),{
  onRedirecting: () => <div>{en.common["loading.."]}</div>
});
