import React from 'react';

// MUI
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// Internal
import en from '../../../data/en.json';

interface OverallFeedbackModalProps {
  overallFeedback: string|null;
  grade: string|null;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (feedback: string|null, grade: string|null) => void;
}

function OverallFeedbackModal(props: OverallFeedbackModalProps) {
  const [overallFeedback, setOverallFeedback] = React.useState<string|null>(props.overallFeedback);
  const [grade, setGrade] = React.useState<string|null>(props.grade);

  const handleSubmit = () => {
    props.onSubmit(overallFeedback, grade);
  };

  return (
    <Modal open={props.isOpen} onClose={props.onClose}>
      <Box sx={{
          transform: 'translate(-50%, -50%)',
          position: 'fixed',
          width: 500,
          bgcolor: 'background.paper',
          top: '50%',
          left: '50%',
          p: 4
        }}
      >
        <Typography variant="h6" component="h2">
          {en.gradedAssignment.addOverallFeedback}
        </Typography>

        <TextField multiline rows={9} fullWidth sx={{mb: 2}}
          label={en.gradedAssignment.overallFeedback} variant="outlined"
          defaultValue={overallFeedback} onChange={(e) => {setOverallFeedback(e.target.value)}}
        />

        <TextField variant="outlined" defaultValue={grade}
          fullWidth label={en.gradedAssignment.grade} sx={{mb: 2}}
          onChange={(e) => {setGrade(e.target.value)}}
        />

        <Button onClick={handleSubmit} variant="contained" color="primary">
          {en.common.submit}
        </Button>
      </Box>
    </Modal>
  )
}

export default OverallFeedbackModal;
