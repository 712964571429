import * as React from 'react';
// MUI
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddBoxIcon from '@mui/icons-material/AddBox';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';

// Third Party
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useLazyQuery } from '@apollo/client';
import {GET_USER_PROFILE_BY_ID} from '../../data/graphql/queries';

// Internal
import AdminOverview from './Overview';
import AdminPublications from './Publications';
import AdminCommunity from './Community';
import AdminNotifications from './Notifications';
import withRoot from '../../withRoot';
import en from '../../data/en.json';

const drawerWidth = 240;

enum AdminDashboardSection {
  overview = "Overview",
  publications = "Publications",
  community = "Community",
  notifications = "Notifications",
} 

function Index() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [activeSection, setActiveSection] = React.useState(AdminDashboardSection.overview);
  const { user, isLoading, logout } = useAuth0();

  const [getUserProfile, 
    { 
      loading: GetUserProfileLoading, 
      error: GetUserProfileError,
      data: GetUserProfileData
    }] = useLazyQuery(GET_USER_PROFILE_BY_ID);

  React.useEffect(() => { 
    if (user) {
      getUserProfile({ variables: { user_auth0_id: user.sub } });
    }
  },[user])


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  if (GetUserProfileLoading) { return <p>{en.common['loading..']}</p>}
  if (GetUserProfileError) { return <p>{en.common.error}: {GetUserProfileError.message}</p> }
  if (GetUserProfileData) {
    if (GetUserProfileData.userProfileById.role !== "admin") {
      return <p>{en.common.error}: {en.adminDashboard.unauthorized}</p>
    }
  }

  const drawer = (
    <div>
      <Toolbar />
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <Link
          variant="h6"
          underline="none"
          href="/"
          sx={{ pl: 2, fontSize: 16, color: grey[50] }}
        >
          {en.common.inkBowl}
        </Link>
      </Box>

      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            selected={activeSection === AdminDashboardSection.overview}
            onClick={() => setActiveSection(AdminDashboardSection.overview)}
          >
            <ListItemIcon>
              <LibraryBooksIcon sx={{ color: grey[50]}} />
            </ListItemIcon>
            <ListItemText primary={AdminDashboardSection.overview} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            selected={activeSection === AdminDashboardSection.publications}
            onClick={() =>
              setActiveSection(AdminDashboardSection.publications)
            }
          >
            <ListItemIcon>
              <AddBoxIcon sx={{ color: grey[50]}} />
            </ListItemIcon>
            <ListItemText primary={AdminDashboardSection.publications} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            selected={activeSection === AdminDashboardSection.community}
            onClick={() => setActiveSection(AdminDashboardSection.community)}
          >
            <ListItemIcon>
              <AccountCircleIcon sx={{ color: grey[50]}} />
            </ListItemIcon>
            <ListItemText primary={AdminDashboardSection.community} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            selected={activeSection === AdminDashboardSection.notifications}
            onClick={() => setActiveSection(AdminDashboardSection.notifications)}
          >
            <ListItemIcon>
              <NotificationsIcon sx={{ color: grey[50]}} />
            </ListItemIcon>
            <ListItemText primary={AdminDashboardSection.notifications} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />

      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Link
          variant="h6"
          component="button"
          underline="none"
          sx={{ pt: 1, color: grey[50] }}
          onClick={() =>
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
        >
          {en.common.logout}
        </Link>
      </Box>
    </div>
  );

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { sm: `${drawerWidth}px` },
            }}
          >
            <Toolbar sx={{backgroundColor: grey[50]}}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon sx={{ color: grey[900] }}/>
              </IconButton>
              {isLoading && <CircularProgress sx={{color: grey[900]}}/> }
              {!isLoading && user &&
                <Typography variant="h6" noWrap component="div" sx={{color: grey[900]}}>
                  {en.userDashboard.hello} {user.name}
                </Typography>
              }
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
              PaperProps={{
                sx: {
                  color: grey[50],
                  backgroundColor: grey[900]
                }
              }}
            >
              {drawer}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: 'none', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
              PaperProps={{
                sx: {
                  color: grey[50],
                  backgroundColor: grey[900]
                }
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>
          <Box
            component="main"
            sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
          >
            <Toolbar />
            {activeSection === AdminDashboardSection.overview && <AdminOverview />}
            {activeSection === AdminDashboardSection.publications && <AdminPublications />}
            {activeSection === AdminDashboardSection.community && <AdminCommunity />}
            {activeSection === AdminDashboardSection.notifications && <AdminNotifications />}
          </Box>
        </Box>
    </React.Fragment>
  
  );
}

export default withAuthenticationRequired(withRoot(Index),{
  onRedirecting: () => <div>{en.common['loading..']}</div>
});