import * as React from "react";

// MUI
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Third Party
import { useMutation } from '@apollo/client';
import { UploadButton } from "@bytescale/upload-widget-react";

// Internal
import CenteredLoadingSpinner from "../../components/CenteredLoadingSpinner";
import { EXTRACT_TEXT_FROM_DOCUMENT_URL } from "../../data/graphql/mutations";
import en from '../../data/en.json';

interface UploadFileProps {
  onSetOriginalText: (originalText: string) => void;
}

function UploadFile(props: UploadFileProps) {
  const ACCEPTED_MIME_TYPES: string[] = [
    "application/pdf",
    "application/docx",
  ]

  const [text, setText] = React.useState('');
  const [documentUrl, setDocumentUrl] = React.useState<string|null>(null);
  const [extractTextFromDocumentUrl, {
    error: ExtractTextFromDocumentUrlError,
    data: ExtractTextFromDocumentUrlData,
    loading: ExtractTextFromDocumentUrlLoading
  }] = useMutation(EXTRACT_TEXT_FROM_DOCUMENT_URL);
  const [extractDocumentError, setExtractDocumentError] = React.useState<string|null>(null);

  const handleTextChange = (event: any) => {
    setText(event.target.value);
    props.onSetOriginalText(event.target.value);
  };

  const handleFileContentExtracted = (fileContent: string) => {
    setText(fileContent);
    props.onSetOriginalText(fileContent);
  };

  React.useEffect(() => {
    if (documentUrl) {
      extractTextFromDocumentUrl({ variables: { document_url: documentUrl } });
    }
  }, [documentUrl]);

  React.useEffect(() => {
    if (ExtractTextFromDocumentUrlData) {
      const rawText = ExtractTextFromDocumentUrlData.extractTextFromDocumentUrl.text;
      const errors = ExtractTextFromDocumentUrlData.extractTextFromDocumentUrl.errors;

      if (errors.length > 0) {
        setExtractDocumentError(errors.join(' '));
      } else {
        const formatText = (text: string) => {
          return text
            .replace(/•|●|▪|♦|‣|⁃/g, '-') // Replace common bullet points with a simple dash or other character
            .replace(/\r\n/g, '\n')  // Replace Windows-style line breaks with Unix-style
            .replace(/[\u200B-\u200D\uFEFF]/g, '') // Remove zero-width spaces
            .replace(/\u00A0/g, ' ')  // Replace non-breaking spaces with regular spaces
            .replace(/\t/g, ' ')    // Replace tabs with spaces (optional)
            .replace(/([.,!?:;])(?=[^\s])/g, '$1 ') // Add space after punctuation if needed
            .split('\n').map(line => line.trim()).join('\n') // Split text into lines
            .trim();
        };

        handleFileContentExtracted(formatText(rawText));
      }
    }
  }, [ExtractTextFromDocumentUrlData])

  if (ExtractTextFromDocumentUrlLoading) {
    return <CenteredLoadingSpinner />;
  }

  if (ExtractTextFromDocumentUrlError) {
    setExtractDocumentError(ExtractTextFromDocumentUrlError.message);
  }

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={2} />
        <Grid item xs={12} md={8}>
          {extractDocumentError && <Alert variant="outlined" severity="error" sx={{ mb: 2 }}>
            <AlertTitle>{en.common.somethingWentWrong}</AlertTitle>
            {extractDocumentError}
            </Alert>
          }
          <Alert variant="outlined" severity="info" sx={{ mb: 2}}>
            {en.common.uploadFileInstructions}
            <UploadButton
              options={{
                apiKey: process.env.REACT_APP_UPLOADER_API_KEY!,
                mimeTypes: ACCEPTED_MIME_TYPES,
              }}
              onComplete={(files) => {
                const fileUrls: string[] = files.map((x) => x.fileUrl);
                setDocumentUrl(fileUrls[0]);
              }}
            >
              {({ onClick }) => (
                <IconButton onClick={onClick}>
                  <Button variant="contained">{en.common.uploadFile}</Button>
                </IconButton>
              )}
            </UploadButton>
          </Alert>
          <TextField
            defaultValue={text}
            disabled={false}
            onChange={handleTextChange}
            fullWidth
            multiline
            rows={18}
            maxRows={Infinity}
            sx={{
              height: '60vh',
                cursor: 'text',
                overflow: 'auto',
                whiteSpace: 'pre-wrap', // Ensures text wraps to new lines
                wordWrap: 'break-word' // Ensures words wrap correctly within the container 
            }}
          />
        </Grid>
        <Grid item xs={12} md={2} />
      </Grid>
    </Box>
  )
}

export default UploadFile;
