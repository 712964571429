import * as React from 'react';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import VerifiedIcon from '@mui/icons-material/Verified';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkIcon from '@mui/icons-material/Link';

// Third Party
import { Link } from 'react-router-dom';
import { UploadButton } from "@bytescale/upload-widget-react";
import {useQuery, useMutation} from '@apollo/client';
import { User as Auth0User } from '@auth0/auth0-react';

// Internal
import {GET_USER_PROFILE_BY_ID} from '../../data/graphql/queries';
import {UPDATE_USER_PRIFILE} from '../../data/graphql/mutations';

import {UserType} from '../../data/types';
import en from "../../data/en.json";

interface MyProfileProps {
  user: Auth0User
}

export default function MyProfile(props: MyProfileProps) {
  const { loading, error, data } = useQuery(GET_USER_PROFILE_BY_ID, {
    variables: { user_auth0_id: props.user.sub },
  });
  const [updateUserProfile, { data: UpdateUserProfileData, error: UpdateUserProfileError  }] = useMutation(UPDATE_USER_PRIFILE);

  const [showEditProfile, setShowEditProfile] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<UserType | null>(null);
  const [handle, setHandle] = React.useState<string|null>(null);
  const [pronouns, setPronouns] = React.useState<string|null>(null);
  const [pictureUrl, setPictureUrl] = React.useState<string|null>(null);
  const [twitter, setTwitter] = React.useState<string|null>(null);
  const [instagram, setInstagram] = React.useState<string|null>(null);
  const [bio, setBio] = React.useState<string|null>(null);
  const [showSuccessAlert, setShowSuccessAlert] = React.useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (data) {
      setUser(data.userProfileById as UserType);

      setHandle(data.userProfileById.handle);
      setPronouns(data.userProfileById.profile.pronouns);
      setPictureUrl(data.userProfileById.profile?.photo?.url);
      setTwitter(data.userProfileById.profile?.twitter);
      setInstagram(data.userProfileById.profile?.instagram);
      setBio(data.userProfileById.profile?.bio);
    }
  }, [data])

  React.useEffect(() => {
    if (UpdateUserProfileData) { setShowSuccessAlert(true); }
    if (UpdateUserProfileError) { setShowErrorAlert(true); }
  }, [UpdateUserProfileData])

  if (loading || !user) { return <p>{en.common['loading..']}</p>}
  if (error) { return <p>{en.common.error}: {error.message}</p> }

  const showUserProfile = (
    <Grid container justifyContent={"center"} alignItems={"center"} flexDirection={"column"} sx={{ mt: 10, pt: 5}}>
      <Grid item xs={2} />
      <Grid item xs={8} sx={{width: 750}}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Avatar sx={{ mr: 1 }} alt="userProfile" src={user.profile.photo?.url} />
          <Typography variant="h5" sx={{mr: 1}}>{user.profile.nickname}</Typography>
          {user.profile.pronouns && <Typography variant="h5" sx={{mr: 1}}>({user.profile.pronouns})</Typography>}
          <Typography variant="h5" sx={{mr: 1}}>@{user.handle}</Typography>
          {user.verified || true && <VerifiedIcon/>}
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center" sx={{pt: 2, pb: 0}}>
          {user.profile.twitter && 
          <Button component={Link} to={user.profile.twitter}  variant="text">
            <TwitterIcon />
          </Button>}
          {user.profile.instagram && 
          <Button component={Link} to={user.profile.instagram} variant="text">
            <InstagramIcon />
          </Button>}
          <Button component={Link} to={`/users/${handle}`}  variant="text">
            <LinkIcon />
          </Button>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center" sx={{pt: 2, pb: 0, m: 2}}>
          {user.profile?.bio && <Typography variant="body1">{user.profile.bio}</Typography>}
          {!user.profile?.bio && <Typography variant="body1">{en.common.noBio}</Typography>}
        </Box>

        
        <Grid item xs={12} sx={{m: 2}}>
          <Button variant="contained" size="large" onClick={() => setShowEditProfile(true)}>
            {en.userDashboard.myProfileSection.editProfile}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )

  const editUserProfile = (
    <Grid container spacing={2} sx={{mt: 2}}>
      <Grid item xs={3} />
      <Grid item xs={6} spacing={2}>
        <Grid item xs={12} sx={{mt: 2}}>
          <TextField
            label={en.userDashboard.myProfileSection.handle}
            required
            value={handle}
            onChange={(e) => {
              setHandle(e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} sx={{mt: 2}}>
          <TextField
            label={en.userDashboard.myProfileSection.pronouns}
            value={pronouns}
            onChange={(e) => {
              setPronouns(e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} sx={{mt: 2}}>
          <TextField
            label={en.common.twitter}
            value={twitter}
            onChange={(e) => {
              setTwitter(e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} sx={{mt: 2}}>
          <TextField
            label={en.common.instagram}
            value={instagram}
            onChange={(e) => {
              setInstagram(e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} sx={{mt: 2}}>
          <TextField
            label={en.common.bio}
            value={bio}
            multiline
            fullWidth
            rows={4}
            onChange={(e) => {
              setBio(e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} sx={{mt: 2}}>
          <UploadButton
            options={{ apiKey: process.env.REACT_APP_UPLOADER_API_KEY! }}
            onComplete={(files) => {
              const fileUrls: string[] = files.map((x) => x.fileUrl);
              setPictureUrl(fileUrls[0]);
            }}
          >
            {({ onClick }) => (
              <Button variant="outlined" onClick={onClick}>
                {en.userDashboard.myProfileSection.uploadProfilePicture}
              </Button>
            )}
          </UploadButton>
        </Grid>

        <Grid item xs={12} sx={{mt: 2}}>
          {pictureUrl && (
            <img
              src={pictureUrl}
              alt="profileImage"
              width={125}
              height="auto"
            />
          )}
        </Grid>

        <Grid item xs={12} sx={{mt: 2}}>
          <Button size="large" onClick={() => setShowEditProfile(false)}>
            {en.common.cancel}
          </Button>

          <Button
            sx={{ml: 2}}
            variant="contained"
            size="large"
            onClick={() =>
              updateUserProfile({
                variables: {
                  user_auth0_id: props.user.sub,
                  handle: handle,
                  pronouns: pronouns,
                  picture_url: pictureUrl,
                  twitter: twitter,
                  instagram: instagram,
                  bio: bio
                },
              })
            }
          >
            {en.userDashboard.myProfileSection.updateProfile}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )


  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {showSuccessAlert && <Grid item xs={12}>
          <Alert severity="success" onClose={() => setShowSuccessAlert(false)}>
            {en.userDashboard.myProfileSection.updateProfileSuccess}
          </Alert>
        </Grid>}

        {showErrorAlert && <Grid item xs={12}>
          <Alert severity="error" onClose={() => setShowErrorAlert(false)}>
            {en.userDashboard.myProfileSection.updateProfileFailure}
          </Alert>
        </Grid>}

        {!showEditProfile && showUserProfile}
        {showEditProfile && editUserProfile}
  
      </Grid>

    </React.Fragment>
  );
} 