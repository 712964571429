import * as React from 'react';
// MUI
import { grey } from '@mui/material/colors';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Third Party
import { Player } from '@lottiefiles/react-lottie-player';
import { User as Auth0User } from '@auth0/auth0-react';
import { Link } from "react-router-dom";

// Internal
import {useQuery} from '@apollo/client';
import {GET_MY_PUBLICATIONS} from '../../data/graphql/queries';
import {PublicationType} from '../../data/types';
import PublicationCard from '../../components/PublicationCard';
import en from '../../data/en.json';
import Loading from '../../assets/animations/loading.json';

interface MyPublicationProps {
  user: Auth0User
}

interface MyPublicationsTabType {
  index: number
  label: string
}

export default function MyPublications(props: MyPublicationProps) {
  const { loading, data } = useQuery(GET_MY_PUBLICATIONS, {
    variables: { user_auth0_id: props.user.sub },
  });

  const myPubliationTabTypes: MyPublicationsTabType[] = [
    { index: 0, label: en.userDashboard.myPublicationsSection.pending },
    { index: 1, label: en.userDashboard.myPublicationsSection.approved },
  ] 

  const [currentTab, setCurrentTab] = React.useState<MyPublicationsTabType>(myPubliationTabTypes[0]);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(myPubliationTabTypes.find(tabType => tabType.index === newValue) || currentTab)
  };
  
  if (loading) {
    return (
      <Player
        autoplay={true}
        loop={true}
        src={Loading}
        style={{ height: '300px', width: '300px' }}
      />
    )
  }

  const myPublications: PublicationType[] = data.myPublications as PublicationType[]
  const selectedPublications: PublicationType[] =
    myPublications.filter((publication: PublicationType) => publication.status === currentTab.label)

  return (
    <React.Fragment>
      <Tabs value={currentTab.index} onChange={handleTabChange}>
        <Tab label={en.userDashboard.myPublicationsSection.pending} />
        <Tab label={en.userDashboard.myPublicationsSection.approved} />
      </Tabs>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      {selectedPublications.length > 0 && 
       selectedPublications.map((publication: PublicationType, index: number) => (
        <Link 
          key={index}
          to={`/publications/${publication.uuid}`}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <Box sx={{m: 2, p: 2, border: 1, borderColor: grey[300], width: 750}}>
            <PublicationCard publication={publication} />
          </Box>
        </Link>
      ))}
      {selectedPublications.length === 0 &&
        <Typography variant='h3' color={grey[300]} sx={{mt: 2}}>
          {en.common.noPublication}
        </Typography>
      }

      </Box>
      
    </React.Fragment>
  )
  
} 