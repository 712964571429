import * as React from 'react';
// MUI
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { grey } from '@mui/material/colors';

// Third Party
import {useQuery} from '@apollo/client';
import { Link } from "react-router-dom";

// Internal
import {GET_PUBLICATIONS} from '../../data/graphql/queries';
import {PublicationType} from '../../data/types';
import PublicationCard from '../../components/PublicationCard';
import en from '../../data/en.json';

export default function PublicationsList() {
  const { loading, error, data } = useQuery(GET_PUBLICATIONS, {
    variables: { paging_offset: 0, paging_limit: 20 },
  });

  if (loading) return <p>{en.common['loading..']}</p>
  if (error) return <p>{en.common.error}: {error.message}</p>
 
  return (
    <Grid container flexDirection="column" alignItems="center">
      {data.publications.map((publication: PublicationType, index: Number) => (
        <Link
          key={publication.uuid}
          to={`/publications/${publication.uuid}`}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <Box sx={{m: 2, p: 2, border: 1, borderColor: grey[300], width: 750}}>
            <PublicationCard publication={publication} showContent={true} showScrollbar={true}/>
          </Box>
        </Link>
      ))}
    </Grid>
  )
}
