import * as React from "react";

import SharedAppBar from "../../components/SharedAppBar";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import PublicationsList from "./PublicationsList";

import en from "../../data/en.json";
import withRoot from '../../withRoot';

function Main() {
  return (
    <React.Fragment>
      <SharedAppBar returnUrl="/" />
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{mt: 10}}>
        <PublicationsList />
      </Box>
  </React.Fragment>
  )
}

export default withRoot(Main);