import * as React from 'react';

// MUI
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import TextField from '@mui/material/TextField';

// Third Party
import { Editor } from '@tinymce/tinymce-react';
import { Player } from '@lottiefiles/react-lottie-player';
import { useMutation } from '@apollo/client';
import { Grammarly, GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";

// Internal
import { DecisionKindEnum, TagType } from "../../../data/types";
import { PublicationType } from "../../../data/types";
import { UPDATE_PUBLICATION } from "../../../data/graphql/mutations";
import { DECIDE_PUBLICATION } from "../../../data/graphql/mutations";
import en from "../../../data/en.json";
import MultipleSelectChip from "../../../components/MultipleSelectChip";
import PublicationDetail from "../../../components/PublicationDetail";
import Typography from "../../marketing/components/Typography";
import Success from '../../../assets/animations/success.json';
import Error from '../../../assets/animations/error.json';

interface EditPublicationStepType {
  step: Number
  label: String
}

interface EditPublicationProps {
  publication: PublicationType
  setEditPublication: (publication: PublicationType|null) => void
}

export default function EditPublication(props: EditPublicationProps) {
  const steps: EditPublicationStepType[] = [
    { step: 0, label: en.adminDashboard.editPublicationSection.preview },
    { step: 1, label: en.adminDashboard.editPublicationSection.edit },
    { step: 2, label: en.adminDashboard.editPublicationSection.decide },
  ]

  const [updatePublication, { error: UpdatePublicationError, data: UpdatePublicationData }] 
  = useMutation(UPDATE_PUBLICATION);

  const [decidePublication, { error: DecidePublicationError, data: DecidePublicationData }] 
  = useMutation(DECIDE_PUBLICATION);

  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [decisionType, setDecisionType] = React.useState<DecisionKindEnum|null>(null);
  const [decisionDetails, setDecisionDetails] = React.useState<string|null>(null);
  const [publicationTitle, setPublicationTitle] = React.useState<string>(props.publication.title);
  const [publicationHeadline, setPublicationHeadline] = React.useState<string>(props.publication.headline);
  const [publicationHtmlContent, setPublicationHtmlContent] = React.useState<string|undefined>(props.publication.htmlContent);
  const [publicationTagNames, setPublicationTagNames] = React.useState<string[]>(props.publication.tags.map((tag: TagType) => tag.name))

  function PreviewStep() {
    return <PublicationDetail publication={props.publication} />
  }

  function EditStep() {
    const handleEditorChange = (content: any, editor: any) => {
      setPublicationHtmlContent(content);
    };

    return (
      <Box
        sx={{
          m: 1,
          pt: 1,
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column"
        }}
      >
        <Button variant="outlined" color="error" sx={{ m: 1 }} onClick={() => setActiveStep(activeStep + 1)}>
          {en.adminDashboard.editPublicationSection.skipEditPublication}
        </Button>
        <TextField
          required
          sx={{ m: 1, width: "50vw", maxWidth: 750 }}
          fullWidth
          label={en.common.title}
          defaultValue={publicationTitle}
          onChange={(e) => {
            setPublicationTitle(e.target.value);
          }}
          variant="outlined"
        />

        <TextField
          required
          sx={{ m: 1, width: "50vw", maxWidth: 750 }}
          label={en.common.headline}
          defaultValue={publicationHeadline}
          onChange={(e) => {
            setPublicationHeadline(e.target.value);
          }}
          variant="outlined"
        />

        <Box display="flex" sx={{ m: 1 }}>
          <MultipleSelectChip
            tagNames={publicationTagNames}
            setTagNames={setPublicationTagNames}
          />
          <Button variant="text">
            {en.adminDashboard.editPublicationSection.createNewTag}
          </Button>
        </Box>

        <Box display="flex" sx={{ m: 1 }}>
        <Grammarly
          clientId={process.env.REACT_APP_GRAMMARLY_API_KEY}
          config={{
            documentDialect: "american",
            autocomplete: "on"
          }}
        >
          <GrammarlyEditorPlugin
            clientId={process.env.REACT_APP_GRAMMARLY_API_KEY}
            config={{
              documentDialect: "american",
              autocomplete: "on"
            }}
          >
            <Editor
              apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
              init={{
                skin: "snow",
                height: 500,
                width: 750,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount code",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic color backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
              onEditorChange={handleEditorChange}
              value={publicationHtmlContent}
            />
            </GrammarlyEditorPlugin>
          </Grammarly>
        </Box>
 
        <Button variant="outlined" color="error" sx={{ m: 1 }} onClick={() => setActiveStep(activeStep + 1)}>
          {en.adminDashboard.editPublicationSection.skipEditPublication}
        </Button>
      </Box>
    );
  }

  function DecideStep() {
    const decisionKinds: DecisionKindEnum[] = [
      DecisionKindEnum.approved,
      DecisionKindEnum.denied,
    ]

    return (
      <Box
        sx={{
          m: 1,
          pt: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexDirection: "column",
          width: "50vw"
        }}
      >
        <Typography variant="h5">
          {en.adminDashboard.editPublicationSection.decideOnPublication}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "row",
          }}
        >
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            defaultValue={decisionType}
          >
            {decisionKinds.map(
              (decisionKind: DecisionKindEnum, index: number) => (
                <FormControlLabel
                  key={index}
                  value={decisionKind}
                  control={<Radio />}
                  label={decisionKind}
                  onChange={() => setDecisionType(decisionKind)}
                />
              )
            )}
          </RadioGroup>
        </Box>

        <Typography variant="h5" sx={{m: 1, width: "100wv"}}>
          {en.adminDashboard.editPublicationSection.commentsOnDecision}
        </Typography>
        <TextField
          id="outlined-multiline-static"
          label="Multiline"
          multiline
          fullWidth
          rows={4}
          defaultValue={decisionDetails}
          onChange={(e) => {
            setDecisionDetails(e.target.value);
          }}
        />
      </Box>
    );
  }

  function handleDecidePublication () {
    updatePublication({ variables: { 
      uuid: props.publication.uuid,
      title: publicationTitle,
      headline: publicationHeadline,
      tag_names: publicationTagNames,
      kind: props.publication.kind,
      status: decisionType,
      painting_url: props.publication.media?.url || null, // if painting_url is undefined, convert to null
      html_content: publicationHtmlContent || null, // if html_content is undefined, convert to null
     }
    })

    decidePublication({ variables: {
      uuid: props.publication.uuid,
      decision: decisionType,
      details: decisionDetails
     }
    })
  }

  if (UpdatePublicationData !== undefined && DecidePublicationData !== undefined) {
    return (
      <Player
        autoplay={true}
        loop={true}
        src={Success}
        style={{ height: '300px', width: '300px' }}
      />
    )
  }

  if (UpdatePublicationError !== undefined || DecidePublicationError !== undefined) {
    return (
      <Player
        autoplay={true}
        loop={true}
        src={Error}
        style={{ height: '300px', width: '300px' }}
      />
    )
  }

  function EditPublicationNavigation() {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" sx={{pt: 2}}>
        { activeStep > 0 && <Button onClick={() => setActiveStep(activeStep - 1)}>{en.common.previous}</Button> }
        { (activeStep < steps.length - 1) && <Button onClick={() => setActiveStep(activeStep + 1)}>{en.common.next}</Button> }
        { (activeStep === steps.length - 1) && <Button onClick={() => handleDecidePublication()}>{en.common.complete}</Button> }
      </Box>
    )
  }

  return (
    <React.Fragment>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color={grey[900]}
          onClick={() => props.setEditPublication(null)}
        >
          {en.adminDashboard.publicaitons}
        </Link>
        <Link underline="none" color={grey[900]}>
          {props.publication.title}
        </Link>
      </Breadcrumbs>

      <Box sx={{ m: 1, pt: 1 }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((step: EditPublicationStepType, index: number) => (
            <Step key={index}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
        {activeStep === 0 && PreviewStep()}
        {activeStep === 1 && EditStep()}
        {activeStep === 2 && DecideStep()}
      </Box>
      {EditPublicationNavigation()}

    </React.Fragment>
  );
}