import React from 'react';
import { marked } from 'marked';

interface MarkdownRendererProps {
  text: string;
}

// Custom style for the markdown
const markdownStyle: React.CSSProperties = {
  fontFamily: 'Arial, sans-serif',
  lineHeight: '1.5',
  fontSize: '16px',
  color: '#333',
};

const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({ text }) => {
  // Replace newlines with <br> tags
  const formattedText = text.replace(/\n/g, '  \n'); // Adding two spaces before \n to ensure line breaks
  // Convert the markdown text to HTML
  const markdownHtml: string = marked(formattedText) as string;

  return (
    <div
      style={markdownStyle}
      dangerouslySetInnerHTML={{ __html: markdownHtml }}
    />
  );
};

export default MarkdownRenderer;
