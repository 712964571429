import * as React from "react";

// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkIcon from '@mui/icons-material/Link';
import EditIcon from '@mui/icons-material/Edit';
import VerifiedIcon from '@mui/icons-material/Verified';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';

// Third Party
import { useParams, Link } from 'react-router-dom';
import {useQuery, useMutation} from '@apollo/client';
import { useAuth0 } from "@auth0/auth0-react";

// Internal
import SharedAppBar from "../../components/SharedAppBar";
import SharedCompose from '../../components/SharedCompose';
import en from "../../data/en.json";
import withRoot from '../../withRoot';
import {GET_USER_PROFILE_BY_HANDLE} from '../../data/graphql/queries';
import {FOLLOW_USER, UPDATE_POST} from '../../data/graphql/mutations';
import {PostType, UserType, PublicationType, TagType} from '../../data/types';

function Main() {
  let { handle } = useParams();
  const { user: auth0User, isAuthenticated } = useAuth0();
  const [followUser, { data: FollowUserData  }] = useMutation(FOLLOW_USER);
  const [updatePost, { data: _UpdatePostData }] = useMutation(UPDATE_POST);
  const { loading, error, data } = useQuery(GET_USER_PROFILE_BY_HANDLE, {
    variables: { handle: handle },
  });

  const [tab, setTab] = React.useState<string>('1');
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };
  const [user, setUser] = React.useState<UserType | null>(null);
  const [editPost, setEditPost] = React.useState<PostType | null>(null);

  React.useEffect(() => {
    if (data) { setUser(data.userProfileByHandle as UserType); }
  }, [data])

  React.useEffect(() => {
    if (FollowUserData) { setUser(FollowUserData.followUser as UserType); }
  }, [FollowUserData])

  if (loading || !user) { return <p>{en.common["loading.."]}</p>}
  if (error) { return <p>{en.common.error}: {error.message}</p> }

  const handleFollowUser = (userAuth0Id: string|undefined, followingUserHandle: string) => {
    if (userAuth0Id) {
      followUser({variables: {
        user_auth0_id: userAuth0Id,
        following_user_handle: followingUserHandle
      }})
    }
  }

  const handleUpdatePost = (postId: string, text: string, imageUrl: string|null) => {
    if (auth0User) {
      updatePost({variables: {
        post_id: postId,
        user_auth0_id: auth0User.sub,
        text: text,
        image_url: imageUrl
      }})
    }
    setEditPost(null);
  }

  const renderFollowButton = () => {
    if (!isAuthenticated || (auth0User && auth0User.sub === user.auth0Id)) { return null; }
    const foundUser = user.followers.find((follower: UserType) => follower.auth0Id === auth0User?.sub)
    const buttonText = foundUser ? en.userProfile.unfollow : en.userProfile.follow
    return <Button variant="contained" onClick={() => handleFollowUser(auth0User?.sub, user.handle)}>{buttonText}</Button>
  }

  const renderEditPostModal = (user: UserType, post: PostType) => {
    return (
      <Modal open={editPost !== null} onClose={()=> setEditPost(null)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            height: '50%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}>
            <SharedCompose user={user} post={post} handleUpdatePost={handleUpdatePost}/>
          </Box>
      </Modal>
    )
   }

  const renderPosts = (posts: PostType[]) => {
    return (
      <React.Fragment>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: '1rem' }}>
        {editPost && renderEditPostModal(user, editPost)}
        {posts.map((post: PostType, index: number) => (
          <Box key={index}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Avatar src={user.profile?.photo?.url} />
              <Typography variant="body1" sx={{ fontWeight: "bold", pr: 1, pl: 1 }}>
                {user.profile?.nickname}
              </Typography>
              <Typography variant="body1" sx={{pr: 1}}>{post.createdAt}</Typography>
              <IconButton onClick={() => setEditPost(post)}>
                <EditIcon />
              </IconButton>
            </Box>
            <Box sx={{ maxHeight: 250, pt: 1, pb: 1, overflow: "hidden", overflowY: "scroll" }}>
              <Typography variant="body2">{post.text}</Typography>
            </Box>
            {post.imageUrl && (
              <img
                alt={en.community.postImage}
                src={post.imageUrl}
                width={"100%"}
                height={"auto"}
              />
            )}
          </Box>
        ))}
      </div>
    </React.Fragment>
    );
  }

  const renderPublications = (publications: PublicationType[]) => {
    return (
      <React.Fragment>
        {publications.map((publication: PublicationType, index: Number) => (
          <Link
            key={publication.uuid}
            to={`/publications/${publication.uuid}`}
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <Box>
              <Typography variant="h4" sx={{p: 1, pl: 0}}>{publication.title}</Typography>
              <Typography variant="body1" sx={{width: 750}}>{publication.headline}</Typography>
              {publication.tags.map((tag: TagType, index: number) => (
                <Chip key={index} label={tag.name} variant="outlined" sx={{mr: 1}}/>
              ))}
            </Box>
          </Link>
        ))}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <SharedAppBar returnUrl="/" />
      <Grid container justifyContent={"center"} alignItems={"center"} flexDirection={"column"} sx={{ mt: 10, pt: 5}}>
        <Grid item xs={2} />
        <Grid item xs={8} sx={{width: 750}}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Avatar sx={{ mr: 1 }} alt="userProfile" src={user.profile.photo?.url} />
            <Typography variant="h5" sx={{mr: 1}}>{user.profile.nickname}</Typography>
            {user.profile.pronouns && <Typography variant="h5" sx={{mr: 1}}>({user.profile.pronouns})</Typography>}
            <Typography variant="h5" sx={{mr: 1}}>@{user.handle}</Typography>
            {user.verified || true && <VerifiedIcon/>}
          </Box>

          <Box display="flex" flexDirection="row" alignItems="center" sx={{pt: 2, pb: 0}}>
            {user.profile.twitter && 
            <Button component={Link} to={user.profile.twitter}  variant="text">
              <TwitterIcon />
            </Button>}
            {user.profile.instagram && 
            <Button component={Link} to={user.profile.instagram} variant="text">
              <InstagramIcon />
            </Button>}
            <Button component={Link} to={`/users/${user.handle}`}  variant="text">
              <LinkIcon />
            </Button>
          </Box>

          <Box display="flex" flexDirection="row" alignItems="center" sx={{pt: 2, pb: 2}}>
            <Typography variant="body1" sx={{mr: 1}}>{user.followers.length} {en.userProfile.follower}</Typography> 
            <Typography variant="body1" sx={{mr: 4}}>{user.following.length} {en.userProfile.following}</Typography> 
            {renderFollowButton()}
          </Box>

          <Box display="flex" flexDirection="row" alignItems="center" sx={{pt: 2, pb: 0, m: 2}}>
            {user.profile?.bio && <Typography variant="body1">{user.profile.bio}</Typography>}
          </Box>
          
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabChange}>
                  <Tab label={en.common.posts} value="1" />
                  <Tab label={en.common.publications} value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                {user.posts.length === 0 && en.userProfile.noPosts}
                {user.posts.length > 0 && renderPosts(user.posts) }
              </TabPanel>
              <TabPanel value="2">
                {user.publications.length === 0 && en.userProfile.noPublications}
                {user.publications.length > 0 && renderPublications(user.publications) }
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </React.Fragment>
  )
}

export default withRoot(Main);