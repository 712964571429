import * as React from "react";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import ImageIcon from '@mui/icons-material/Image';
import IconButton from '@mui/material/IconButton';
import { grey } from "@mui/material/colors";

// Third Party
import { UploadButton } from "@bytescale/upload-widget-react";

// Internal 
import en from '../data/en.json';
import { UserType, PostType } from '../data/types';

interface SharedComposeProps {
  user: UserType | null;
  post?: PostType;
  handleCreatePost?: (text: string, imageUrl: string|null) => void;
  handleUpdatePost?: (postId: string, text: string, imageUrl: string|null) => void;
}

function SharedCompose(props: SharedComposeProps) {
  const [text, setText] = React.useState<string>("");
  const [imageUrl, setImageUrl] = React.useState<string|null>(null);
  React.useEffect(() => {
    if (props.post) {
      setText(props.post.text);
      if (props.post.imageUrl) {
        setImageUrl(props.post.imageUrl);
      }
    }

  }, [props.post, setText, setImageUrl])

  const handleCreatePost = () => {
    if (props.handleCreatePost) {
      props.handleCreatePost(text, imageUrl);
      setText("");
      setImageUrl(null);
    }
  }

  const handleUpdatePost = () => {
    if (props.handleUpdatePost && props.post) {
      props.handleUpdatePost(props.post.id, text, imageUrl);
      setText("");
      setImageUrl(null);
    }
  }

  return (
    <React.Fragment>
      <Grid container direction="row" sx={{ p: 2 }}>
        <Grid item alignSelf="self-start" xs={2} sx={{ pl: 2 }}>
          <Avatar src={props.user?.profile?.photo?.url} />
        </Grid>
        <Grid item xs={10}>
          <TextField
            multiline
            fullWidth
            variant="standard"
            sx={{ maxHeight: 250, overflow: "hidden", overflowY: "scroll" }}
            label={en.community.whatIsOnYourMind}
            InputProps={{ disableUnderline: true }}
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
          />
          {imageUrl && <img src={imageUrl} width={100} alt={en.community.postImage} />}
          <Grid container justifyContent={"space-between"} sx={{ pt: 2 }}>
            <Grid item>
              <UploadButton
                options={{apiKey: process.env.REACT_APP_UPLOADER_API_KEY!}}
                onComplete={(files) => {
                  const fileUrls: string[] = files.map((x) => x.fileUrl);
                  setImageUrl(fileUrls[0]);
                }}
              >
                {({ onClick }) => (
                  <IconButton onClick={onClick}>
                    <ImageIcon />
                  </IconButton>
                )}
              </UploadButton>
            </Grid>
            {!props.post && <Button
              disabled={text === ""}
              variant="contained"
              onClick={() => handleCreatePost()}
            >
              {en.common.createPost}
            </Button>}
            {props.post && <Button
              variant="contained"
              onClick={() => handleUpdatePost()}
            >
              {en.common.updatePost}
            </Button>}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SharedCompose;